import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PremiumBannerContainer = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};
`;
