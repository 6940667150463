import { Timestamp as FirebaseTimestamp } from 'firebase/firestore';

import { Timestamp } from './timestamp';

export enum RruleReferenceDate {
  StartDate = 'startDate',
  LastCompletion = 'lastCompletion',
}

export enum FrequencyUnit {
  Week = 'week',
  Month = 'month',
}
export enum TargetFrequencyUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

type ScheduleBase = {
  startDate: Timestamp;
  rrule: {
    format: string;
    referenceDate: RruleReferenceDate;
  };
  targetFrequency?: {
    count: number;
    unit: TargetFrequencyUnit;
  };
  completions: Timestamp[];
  skips?: Timestamp[] | null;
  endDate?: Timestamp | null;
};

export type HabitScheduleTimesPer = ScheduleBase & {
  frequency: {
    count: number;
    unit: FrequencyUnit;
  };
};

export type HabitScheduleSpecificDays = ScheduleBase & {
  frequency: null;
};

export type HabitSchedule = HabitScheduleTimesPer | HabitScheduleSpecificDays;

export type FirebaseReturnSchedule = {
  startDate: FirebaseTimestamp | null;
  endDate: FirebaseTimestamp | null;
  completions: FirebaseTimestamp[] | null;
  skips: FirebaseTimestamp[] | null;
};
