import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './form-input.style';

export type FormInputProps = React.ComponentProps<'input'> & {
  label?: React.ReactNode;
  error?: string;
  iconLeft?: React.FC;
  iconRight?: React.FC;
  canClear?: boolean;
  onClear?: () => void;
  postFix?: string;
  transparent?: boolean;
};

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      id,
      error,
      disabled,
      iconLeft,
      iconRight,
      canClear,
      onClear,
      value,
      postFix,
      transparent,
      type,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const inputRef = useForwardedRef(ref);

    const clearInput = () => {
      const valueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )!.set;
      if (!valueSetter || !inputRef.current) {
        return;
      }

      valueSetter.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      onClear?.();
    };

    const increaseNumber = () => inputRef.current?.stepUp();

    const decreaseNumber = () => inputRef.current?.stepDown();

    return (
      <Styled.Container>
        {!!label && (
          <Styled.Label htmlFor={id} $disabled={!!disabled}>
            {label}
          </Styled.Label>
        )}
        <Styled.InputContainer>
          <Styled.StartContainer>
            {!!iconLeft && (
              <Styled.IconRightContainer>
                <Icon icon={iconLeft} />
              </Styled.IconRightContainer>
            )}
          </Styled.StartContainer>

          <Styled.Input
            id={id}
            disabled={disabled}
            value={value}
            type={type}
            {...rest}
            enterKeyHint="enter"
            ref={inputRef}
            $hasError={!!error}
            $hasLeft={!!iconLeft}
            $hasRight={!!iconRight || !!postFix || !!canClear}
            $transparent={!!transparent}
          />

          <Styled.EndContainer $noPointerEvents={canClear ? !value : true}>
            {!!postFix && <Styled.PostFix>{postFix}</Styled.PostFix>}

            {!!iconRight && (canClear ? !value : true) && (
              <Styled.IconRightContainer>
                <Icon icon={iconRight} />
              </Styled.IconRightContainer>
            )}

            {type === 'number' && (
              <Styled.NumberButtonsContainer>
                <Styled.NumberButton
                  onClick={increaseNumber}
                  type="button"
                  tabIndex={-1}
                >
                  <Icon icon={ChevronLeft} />
                </Styled.NumberButton>

                <Styled.NumberButton
                  onClick={decreaseNumber}
                  type="button"
                  tabIndex={-1}
                  $isSecond
                >
                  <Icon icon={ChevronLeft} />
                </Styled.NumberButton>
              </Styled.NumberButtonsContainer>
            )}

            {canClear && !!value && (
              <Styled.ClearButton
                onClick={clearInput}
                aria-label={t('general.labels.clear-field')}
                type="button"
                tabIndex={-1}
              >
                <Icon icon={X} />
              </Styled.ClearButton>
            )}
          </Styled.EndContainer>
        </Styled.InputContainer>
      </Styled.Container>
    );
  },
);
