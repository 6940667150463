import React from 'react';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './meta-tag.style';

export type MetaTagProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
};

export const MetaTag: React.FC<MetaTagProps> = ({ icon, name }) => (
  <Styled.Container>
    <Icon icon={icon} />
    <Styled.Label>{name}</Styled.Label>
  </Styled.Container>
);
