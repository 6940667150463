import { Over } from '@dnd-kit/core';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { TaskCardTask } from 'shared/types/task-card-task';
import { isOtherSection } from 'shared/utils/goal-section-id-helpers';

import { Item } from './types';

export const handleTasksBetweenSections = (
  activeItem: TaskCardTask,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const activeParentGoalIndex = items.findIndex(
    ({ id }) => id === activeItem.fields.goalId,
  );
  const activeParentGoal = items[activeParentGoalIndex] as Goal | undefined;
  const overItemIndex = items.findIndex(({ id }) => id === over.id.toString());

  if (!activeParentGoal) {
    return { items, changedItems: [] };
  }

  const activeParentSectionIndex = activeParentGoal.sections?.findIndex(
    ({ tasks }) => tasks.includes(activeItem.id),
  );
  const activeParentSection =
    activeParentGoal.sections?.[activeParentSectionIndex!];

  if (
    over.data.current?.type === DraggableOptions.Goal &&
    // making sure it moved from one goal to another
    activeParentGoalIndex !== overItemIndex
  ) {
    const overGoal = overItem as Goal;
    const newIndex =
      activeParentGoalIndex < overItemIndex
        ? 0
        : overGoal.taskSorting?.length ?? 0;

    // update the values
    activeParentGoal.taskSorting =
      activeParentGoal.taskSorting?.filter((id) => id !== activeItem.id) ??
      null;

    if (activeParentSection) {
      activeParentSection.tasks =
        activeParentSection.tasks?.filter((id) => id !== activeItem.id) ?? [];
    }

    activeItem.fields.goalId = overGoal.id;
    overGoal.taskSorting = (overGoal.taskSorting ?? []).toSpliced(
      newIndex,
      0,
      activeItem.id,
    );
    return {
      items: [...items],
      changedItems: [activeParentGoal, overGoal, activeItem],
    };
  }

  if (over.data.current?.type === DraggableOptions.GoalSection) {
    if (
      // if the same activeSection as before, no movement is needed
      activeParentSection?.id === overItem.id ||
      // Check "other" section by checking if the activeItem didn't have a goal-section and see if it didn't move between goals
      (!activeParentSection && activeParentGoal.id === overItem.id)
    ) {
      return { items: [...items], changedItems: [] };
    }

    // check if the overItem is a goal, as the "other" section of a goal does not have its own section data-wise
    if (over.id.toString() !== overItem.id) {
      // "other" section
      const overGoal = overItem as Goal;
      const overGoalIndex = items.findIndex(({ id }) => id === overGoal?.id);
      let newIndex =
        activeParentGoalIndex <= overGoalIndex
          ? 0
          : overGoal.taskSorting?.length ?? 0;

      if (activeParentSection) {
        activeParentSection.tasks =
          activeParentSection.tasks?.filter((id) => id !== activeItem.id) ?? [];
      }
      activeParentGoal.taskSorting =
        activeParentGoal.taskSorting?.filter((id) => id !== activeItem.id) ??
        null;

      overGoal.taskSorting = (overGoal.taskSorting ?? []).toSpliced(
        newIndex,
        0,
        activeItem.id,
      );

      activeItem.fields.goalId = overGoal.id;

      return {
        items: [...items],
        changedItems: [
          activeParentGoal,
          ...(activeParentGoal.id === overGoal.id
            ? []
            : [overGoal, activeItem]),
        ],
      };
    }

    const overSection = overItem as GoalSection;
    const overGoalIndex = items.findIndex(
      (item) =>
        !('fields' in item) &&
        (item.id === overItem.id ||
          item.sections?.some(({ id }) => id === overItem.id)),
    );
    const overGoal = items[overGoalIndex] as Goal | undefined;

    if (!overGoal) {
      return { items, changedItems: [] };
    }

    let newIndex =
      activeParentGoalIndex < overGoalIndex
        ? 0
        : overSection.tasks?.length ?? 0;

    if (activeParentSection && activeParentGoalIndex === overGoalIndex) {
      const overSectionIndex = isOtherSection(overSection.id)
        ? overGoal.sections!.length
        : overGoal.sections!.findIndex(({ id }) => id === overSection.id);

      newIndex =
        activeParentSectionIndex! < overSectionIndex
          ? 0
          : overSection.tasks?.length ?? 0;
    }

    // set the new values
    activeItem.fields.goalId = overGoal.id;
    overSection.tasks = Array.from(new Set(overSection.tasks ?? [])).toSpliced(
      newIndex,
      0,
      activeItem.id,
    );

    if (activeParentSection) {
      activeParentSection.tasks =
        activeParentSection.tasks?.filter((id) => id !== activeItem.id) ?? [];
    }

    activeParentGoal.taskSorting =
      activeParentGoal.taskSorting?.filter((id) => id !== activeItem.id) ??
      null;

    return {
      items: [...items],
      changedItems: [
        activeParentGoal,
        ...(activeParentGoal.id === overGoal.id ? [] : [overGoal, activeItem]),
      ],
    };
  }

  return { items, changedItems: [] };
};
