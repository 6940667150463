import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flag, IconFormIcons, Repeat, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { GoalTemplate } from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import { getDurationLabel } from './get-duration-label';
import * as Styled from './goal-template-card.style';
import { MetaTag } from './meta-tag';

export type GoalTemplateCardProps = {
  template: GoalTemplate;
  onClick: (id: ID) => void;
};

export const GoalTemplateCard: React.FC<GoalTemplateCardProps> = ({
  template,
  onClick,
}) => {
  const { t, i18n } = useTranslation();

  const _onClick = () => onClick(template.id);

  const mainGoal = useMemo(
    () => template.goals.find(({ isMain }) => isMain),
    [template.goals],
  );

  const habits = useMemo(
    () => template.tasks.filter(({ type }) => type === TaskType.Habit),
    [template.tasks],
  );
  const tasks = useMemo(
    () => template.tasks.filter(({ type }) => type !== TaskType.Habit),
    [template.tasks],
  );

  const duration = mainGoal ? getDurationLabel(mainGoal, t) : '';

  return (
    <Styled.Container onClick={_onClick}>
      <Styled.Content>
        <Styled.Illustration $color={template.color}>
          <Icon icon={IconFormIcons[template.iconName!] ?? Target} />
        </Styled.Illustration>

        <Styled.Body>
          <Typography variant="subtitle2">
            {getLocalizedStringValue(template.name, i18n.language)}
          </Typography>
        </Styled.Body>

        {(!!habits.length || !!tasks.length) && (
          <Styled.Footer>
            <Styled.MetaContent>
              {!!duration && <MetaTag icon={Flag} name={duration} />}
            </Styled.MetaContent>

            <Styled.MetaContent>
              {!!habits.length && (
                <MetaTag
                  icon={Repeat}
                  name={t('goal-template.meta.habits.label', {
                    count: habits.length,
                  })}
                />
              )}
            </Styled.MetaContent>
          </Styled.Footer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
