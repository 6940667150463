import { getToken } from 'firebase/messaging';
import { getRollbar } from 'shared/services/error-tracking';
import { ID } from 'shared/types/id';

import {
  getPushNotificationToken,
  initPushNotifications,
  setPushNotificationToken,
} from './helpers';

let serviceWorkerRegistration: ServiceWorkerRegistration | undefined;

(async function getRegisteredServiceWorker() {
  serviceWorkerRegistration = await window.navigator.serviceWorker?.ready;
})();

export const getPushToken = async (uid: ID) => {
  if (!uid) {
    return;
  }

  try {
    const messaging = initPushNotifications();

    const token =
      getPushNotificationToken(uid) ??
      (await window.electron?.getFcmToken?.()) ??
      (await getToken(messaging, {
        serviceWorkerRegistration,
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
      }));

    setPushNotificationToken(uid, token);

    return token;
  } catch (error) {
    getRollbar().error(
      'failed to get firebase push-notification token',
      error as Error,
    );
  }
};
