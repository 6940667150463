import { defaultLimitKeyMap, premiumLimitKeyMap } from 'shared/constants';
import { LimitKeys, limitKeys } from 'shared/types/limit-keys';
import { UserLimits } from 'shared/types/user-limits';

type Options = {
  limitKeyMap?: Record<LimitKeys, number>;
  enforcedOverwrites?: Partial<Record<LimitKeys, Date | null>>;
  enforcedAt?: Date;
};

const defaultOptions = {
  limitKeyMap: defaultLimitKeyMap,
  enforcedOverwrites: {},
  enforcedAt: new Date(),
};

export const getLimits = ({
  enforcedOverwrites = defaultOptions.enforcedOverwrites,
  limitKeyMap = defaultOptions.limitKeyMap,
  enforcedAt = defaultOptions.enforcedAt,
}: Options = defaultOptions) =>
  limitKeys.reduce<UserLimits>((acc, key) => {
    acc[key] = {
      limit: limitKeyMap[key],
      enforcedAt: enforcedOverwrites[key] ?? enforcedAt,
    };
    return acc;
  }, {} as UserLimits);

export const getPremiumLimits = (enforcedAt = defaultOptions.enforcedAt) =>
  getLimits({ limitKeyMap: premiumLimitKeyMap, enforcedAt });
