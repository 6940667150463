import { getGlobalAllowedSearchParameters } from 'shared/lib/react-router-dom';
import { getRollbar } from 'shared/services/error-tracking';
import { createNonce } from 'shared/services/local-storage';

import { getAppleId } from './get-apple-id';

export const getAppleToken = async () => {
  const appleId = await getAppleId();

  if (!appleId) {
    getRollbar().error('no apple id found');
  }

  const { hashed } = await createNonce();

  const authOptions = {
    nonce: hashed,
    scope: 'email name',
    clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
    redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
    state: JSON.stringify(
      Object.fromEntries(getGlobalAllowedSearchParameters()),
    ),
  };

  appleId.auth.init(authOptions);

  await appleId.auth.signIn();
};
