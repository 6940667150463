import { ID } from 'shared/types/id';
import { InheritColors } from 'shared/types/inherit-colors';
import { LifeArea } from 'shared/types/life-area';

type Options = {
  goals: {
    id: ID;
    parentIds?: ID[] | null;
    lifeAreaId?: ID | null;
    color?: string | null;
  }[];
  lifeAreas: LifeArea[];
};

export const getGoalColor = (
  goalId: ID,
  { lifeAreas, goals }: Options,
): string | null => {
  const goal = goals.find(({ id }) => id === goalId);

  if (goal?.color === InheritColors.Goal) {
    const parentId = goal.parentIds?.[goal.parentIds.length - 1];
    if (!parentId) {
      return null;
    }

    return getGoalColor(parentId, { lifeAreas, goals });
  }

  if (goal?.color === InheritColors.LifeArea) {
    const lifeArea = lifeAreas.find(({ id }) => id === goal.lifeAreaId);
    if (!lifeArea) {
      return null;
    }

    return lifeArea.color ?? null;
  }

  return goal?.color ?? null;
};
