import { Goal } from 'shared/types/goal';
import { GoalFormFields } from 'shared/types/goal-form';
import { MetricEntry, MetricUnit } from 'shared/types/goal-metric';
import { MetricTemplate } from 'shared/types/metric-template';

export type Options = {
  metricTemplates: MetricTemplate[];
  metricEntries: MetricEntry[] | null;
};

export const mapGoalFieldsToGoal = (
  goalFields: GoalFormFields,
  { metricTemplates, metricEntries }: Options,
): Partial<Goal> | undefined => {
  if (!goalFields.name) {
    return;
  }

  const goal: Partial<Goal> = {
    name: goalFields.name,
    image: goalFields.image ?? null,
    iconName: goalFields.iconName ?? null,
    parentIds: goalFields.parentIds ?? null,
    startDate: goalFields.startDate ?? null,
    deadline: goalFields.deadline ?? null,
    lifeAreaId: goalFields.lifeAreaId ?? null,
    color: goalFields.color ?? null,
    metric: null,
  };

  if (goalFields.metricId) {
    const { metricId, metricUnit, metricTargetValue, metricStartValue } =
      goalFields;
    const metricTemplate = metricTemplates.find(({ id }) => id === metricId);

    if (metricTemplate) {
      const templateUnit = metricUnit
        ? metricTemplate.unitOptions?.find(
            ({ id, name }) =>
              id === metricUnit || Object.values(name).includes(metricUnit),
          )
        : undefined;

      const unit: MetricUnit = {
        id: templateUnit?.id ?? 'custom',
        name: templateUnit?.name ?? metricUnit ?? '',
        symbol: templateUnit?.symbol ?? null,
        format: templateUnit?.format ?? null,
      };

      goal.metric = {
        id: metricId,
        startValue: metricStartValue ?? null,
        targetValue: metricTargetValue ?? null,
        unit,
        entries: metricEntries,
      };
    }
  }

  return goal;
};
