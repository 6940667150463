import {
  AuthError,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { Locales } from 'shared/assets/localization';
import { getGlobalAllowedSearchParameters } from 'shared/lib/react-router-dom';
import { SignupErrorTypes } from 'shared/types/signup-form';

import { finishUserSignup } from './finish-user-signup';
import { getAuthentication } from './helpers';

export const signupUser = async (
  email: string,
  password: string,
  name?: string,
  locale?: Locales,
) => {
  const auth = await getAuthentication();

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    await finishUserSignup({
      uid: auth.currentUser?.uid,
      name,
      locale,
      queryParameters: Object.fromEntries(getGlobalAllowedSearchParameters()),
    });

    return userCredential.user.uid;
  } catch (e) {
    if ((e as AuthError).message.includes(AuthErrorCodes.EMAIL_EXISTS)) {
      throw SignupErrorTypes.EmailTaken;
    }

    switch ((e as AuthError).code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        throw SignupErrorTypes.EmailTaken;
      default:
        throw SignupErrorTypes.Unknown;
    }
  }
};
