import { TFunction } from 'i18next';
import { GoalTemplateInstance } from 'shared/types/goal-template';

export const getDurationLabel = (goal: GoalTemplateInstance, t: TFunction) => {
  const durationInDays =
    (goal.daysTillDeadline ?? 0) - (goal.daysTillStartDate ?? 0);

  const durationInWeeks = durationInDays / 7;

  const durationInMonths = durationInDays / 30;

  if (!durationInDays) {
    return '';
  }

  if (durationInDays < 7) {
    return t('goal-template.meta.duration.days.label', {
      // get the count rounded to halves
      count: Math.round(durationInDays * 2) / 2,
    });
  }

  if (durationInWeeks < 13) {
    return t('goal-template.meta.duration.weeks.label', {
      // get the count rounded to halves
      count: Math.round(durationInWeeks * 2) / 2,
    });
  }

  return t('goal-template.meta.duration.months.label', {
    // get the count rounded to halves
    count: Math.round(durationInMonths * 2) / 2,
  });
};
