import { format, isSameYear } from 'date-fns';
import { TFunction } from 'i18next';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate?: Timestamp;
  endDate?: Timestamp;
  today: Timestamp;
  t: TFunction;
};

export const getStartTillEndLabel = ({
  startDate,
  endDate,
  today,
  t,
}: Options) => {
  const formattedStartDate = startDate
    ? format(startDate, isSameYear(today, startDate) ? 'd MMM' : 'd MMM yyyy')
    : t('general.labels.no-start-date');

  const formattedDeadline = endDate
    ? format(endDate, isSameYear(today, endDate) ? 'd MMM' : 'd MMM yyyy')
    : t('general.labels.no-deadline');

  return startDate || endDate
    ? `${formattedStartDate} - ${formattedDeadline}`
    : t('general.labels.no-schedule');
};
