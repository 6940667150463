import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ID } from 'shared/types/id';
import {
  currencySignMap,
  Discount,
  Price,
  PriceInterval,
} from 'shared/types/price';

import * as Styled from './payment-options.style';

type LoaderOption = {
  id: ID;
  translationLabel: string;
  selected?: boolean;
};

const loaderOptions: LoaderOption[] = [
  {
    id: 'year',
    translationLabel: 'dialogs.premium.payment-options.period.year',
    selected: true,
  },
  {
    id: 'month',
    translationLabel: 'dialogs.premium.payment-options.period.month',
  },
];

export type LoadingPaymentOptionsProps = {
  options?: LoaderOption[];
};

export const LoadingPaymentOptions: React.FC<LoadingPaymentOptionsProps> = ({
  options = loaderOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.List>
      {options.map(({ id, translationLabel, selected }) => (
        <Styled.ListItem key={id}>
          <Styled.OptionButton $selected={!!selected} disabled>
            <Styled.OptionHeader>
              <Typography variant="h6" component="span">
                {t(translationLabel)}
              </Typography>
            </Styled.OptionHeader>

            <Styled.OptionBody>
              <Typography variant="subtitle2" component="span">
                <Skeleton animation="wave" width="10.4rem" />
              </Typography>
            </Styled.OptionBody>
          </Styled.OptionButton>
        </Styled.ListItem>
      ))}
    </Styled.List>
  );
};

export type PaymentOptionsProps = {
  options: Price[];
  discount?: Discount;
  onOption: (id: ID) => void;
  selectedOption: string;
  disabled?: boolean;
};

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  options,
  discount,
  onOption,
  selectedOption,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.List>
      {options.map(
        ({ id, interval, amountInCents, currency, savingPercentage }) => {
          const isSelected = id === selectedOption;
          const onClick = () => onOption(id);

          const optionDiscount = discount?.applicableIntervals.includes(
            interval,
          )
            ? discount
            : undefined;

          // take the discount percentage of the price
          const discountedPrice =
            amountInCents * ((100 - (optionDiscount?.percentage ?? 0)) / 100);
          const centsPerMonth =
            interval === PriceInterval.Month
              ? discountedPrice
              : discountedPrice / 12;

          const currencySign = currencySignMap[currency] ?? currency;

          return (
            <Styled.ListItem key={id}>
              <Styled.OptionButton
                $selected={isSelected}
                onClick={onClick}
                disabled={disabled}
              >
                <Styled.OptionHeader>
                  <Typography variant="h6" component="span">
                    {t(`dialogs.premium.payment-options.period.${interval}`)}
                  </Typography>
                  {interval === PriceInterval.Year && (
                    <Styled.PricesContainer>
                      {discountedPrice !== amountInCents && (
                        <Styled.OldPrice>
                          {currencySign}
                          {(amountInCents / 100).toFixed(2)}
                        </Styled.OldPrice>
                      )}
                      <Styled.Price>
                        {currencySign}
                        {(discountedPrice / 100).toFixed(2)}
                      </Styled.Price>
                    </Styled.PricesContainer>
                  )}
                </Styled.OptionHeader>

                <Styled.OptionBody>
                  <Typography variant="subtitle2" component="span">
                    {t(`dialogs.premium.payment-options.interval.${interval}`, {
                      amount: (Math.round(centsPerMonth) / 100).toFixed(2),
                      currency: currencySign,
                    })}
                  </Typography>
                </Styled.OptionBody>

                {(!!optionDiscount?.percentage || !!savingPercentage) && (
                  <Styled.Discount>
                    {t(
                      optionDiscount?.percentage
                        ? 'dialogs.premium.payment-options.discount.percentage'
                        : 'dialogs.premium.payment-options.saving.percentage',
                      {
                        percentage: Math.round(
                          optionDiscount?.percentage ?? savingPercentage,
                        ),
                      },
                    )}
                  </Styled.Discount>
                )}
              </Styled.OptionButton>
            </Styled.ListItem>
          );
        },
      )}
    </Styled.List>
  );
};
