import { useCreateUnlimitedGoalsAllowed, useGoals } from 'features/goals';
import {
  filterGoalsOnLifeArea,
  useLocalizedLifeAreaById,
  useLocalizedLifeAreas,
} from 'features/life-areas';
import {
  useFavoriteIds,
  useUpdateLifeAreaGoalOrder,
  useUserHadTrial,
} from 'features/user';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { EmptyGoals } from 'shared/components/ui/empty-goals';
import {
  GoalDataGrid,
  SelfSortingGoalDataGrid,
} from 'shared/components/ui/goal-data-grid';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { sortItems } from 'shared/utils/sort-items';
import { AddGoalContextMenu } from 'src/shared/components/connected/add-goal-context-menu';

import * as Styled from './goals-data-grid.style';

export const GoalsDataGrid: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { id: idParam } = useParams();
  // early return not possible with hooks, so this is the way to go for now
  const id = idParam ?? '';

  const lifeArea = useLocalizedLifeAreaById(id);
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const userHadTrial = useUserHadTrial();
  const favoriteIds = useFavoriteIds();
  const openPremiumDialog = useOpenPremiumDialog();
  const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();
  const openCreateDialog = useOpenCreateGoalDialog();

  const { updateOrder } = useUpdateLifeAreaGoalOrder(lifeArea);

  const { position, open, close } = useContextMenuState();

  const onCreateFromEmpty = (e: React.MouseEvent) =>
    createGoalAllowed ? open(e) : openPremiumDialog();

  const onCreate = () => openCreateDialog({ lifeAreaId: id });

  const lifeAreaGoals = useMemo(
    () => filterGoalsOnLifeArea(goals, id),
    [goals, id],
  );

  const activeGoals = useMemo(
    () =>
      sortItems(
        lifeAreaGoals.filter(({ frozenAt }) => !frozenAt),
        lifeArea?.goalSorting ?? [],
        'id',
      ),
    [lifeAreaGoals, lifeArea?.goalSorting],
  );

  const frozenGoals = useMemo(
    () => lifeAreaGoals.filter(({ frozenAt }) => !!frozenAt),
    [lifeAreaGoals],
  );

  return lifeAreaGoals.length ? (
    <Styled.GridContainer>
      <SelfSortingGoalDataGrid
        goals={activeGoals}
        allGoals={goals}
        lifeAreas={lifeAreas}
        favorites={favoriteIds}
        onReorder={updateOrder}
        onCreateGoal={createGoalAllowed ? onCreate : undefined}
      />
      {!createGoalAllowed && (
        <>
          <Styled.PremiumBannerContainer>
            <PremiumBanner
              title={t('pages.goals.premium-banner.title')}
              description={t(
                userHadTrial
                  ? 'pages.goals.premium-banner.description'
                  : 'pages.goals.premium-banner.try-for-free.description',
              )}
              onClick={openPremiumDialog}
              isTrial={!userHadTrial}
            />
          </Styled.PremiumBannerContainer>
          <GoalDataGrid
            goals={frozenGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onCreateGoal={openPremiumDialog}
            onGoal={openPremiumDialog}
            isFrozen
            hideHeader
            showPremiumLabel
          />
        </>
      )}
    </Styled.GridContainer>
  ) : (
    <Styled.EmptyContainer ref={containerRef}>
      <EmptyGoals
        onAdd={createGoalAllowed ? onCreateFromEmpty : openPremiumDialog}
      />
      {!!position && (
        <AddGoalContextMenu
          ref={contextMenuRef}
          referenceElement={buttonRef}
          lifeAreaId={id}
          position="top-end"
          onClose={close}
        />
      )}
    </Styled.EmptyContainer>
  );
};
