import { formatISO } from 'date-fns';
import { httpsCallable } from 'firebase/functions';

import { getFunctions } from './helpers';

export const createEntryGoal = async () => {
  const functions = getFunctions();
  const createGoalTemplate = httpsCallable<string, { message: string }>(
    functions,
    'templates-createEntryGoal',
  );

  return createGoalTemplate(
    JSON.stringify({ startDate: formatISO(new Date()) }),
  );
};
