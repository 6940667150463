import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(2)} 0`};
`;

export const Body = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: ${({ theme }) => theme.spacing(3)};
  width: 100%;

  background: ${({ theme }) => theme.gradients.premiumSideways};
  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  text-align: left;
`;

export const IconContainer = styled.span`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 2rem;
`;

export const Content = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.palette.common.white};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  opacity: 0.6;
`;
