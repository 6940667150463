import { useMemo } from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

import { useHabits } from './use-habits';

export const useCreateHabitAllowed = () => {
  const habits = useHabits({
    uncompletedOnly: true,
    unFrozenOnly: true,
    unArchivedOnly: true,
  });

  const filteredHabits = useMemo(
    () => habits.filter(({ excludedFromLimits }) => !excludedFromLimits),
    [habits],
  );

  const { allowed, enforced, limit } = usePremiumFeatureAllowed(
    LimitKeys.CreateHabit,
    {
      total: filteredHabits.length,
    },
  );

  return {
    allowed,
    enforced,
    limit,
    total: filteredHabits.length,
  };
};
