import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import { useCallback } from 'react';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import {
  useOpenCreateGoalDialog,
  useOpenEditGoalDialog,
} from 'shared/contexts/goal-form';
import { useOpenGoalProgress } from 'shared/contexts/goal-progress';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { Goal } from 'shared/types/goal';
import { GoalFormFields } from 'shared/types/goal-form';
import { ID } from 'shared/types/id';

import { useArchiveGoal } from './use-archive-goal';
import { useUpdateGoal } from './use-update-goal';

export type Options = {
  onCreatePresets?: GoalFormFields;
};

export const useGoalControls = (
  goals: Goal[],
  { onCreatePresets }: Options = {},
) => {
  const openEditDialog = useOpenEditGoalDialog();
  const openCreateDialog = useOpenCreateGoalDialog();
  const openDeleteDialog = useOpenDeleteGoalDialog();
  const openGoalProgressDialog = useOpenGoalProgress();
  const openPremiumDialog = useOpenPremiumDialog();
  const archiveGoal = useArchiveGoal();
  const { submit: onUpdateGoal } = useUpdateGoal();

  const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();

  const onEditGoal = useCallback(
    (id: ID) => {
      const goal = goals.find((goal) => goal.id === id);
      !!goal && openEditDialog(goal, id);
    },
    [goals, openEditDialog],
  );

  const onDeleteGoal = useCallback(
    (id: ID) => {
      const goal = goals.find((goal) => goal.id === id);
      !!goal && openDeleteDialog(goal);
    },
    [goals, openDeleteDialog],
  );

  const onArchiveGoal = useCallback(
    (id: ID) => {
      const goal = goals.find((goal) => goal.id === id);
      !!goal && archiveGoal(goal);
    },
    [archiveGoal, goals],
  );

  const onCreateGoal = useCallback(
    (name?: string) => {
      !createGoalAllowed
        ? openPremiumDialog()
        : openCreateDialog({
            name,
            ...(onCreatePresets ?? {}),
          });
    },
    [createGoalAllowed, onCreatePresets, openCreateDialog, openPremiumDialog],
  );

  const onCreateSubGoal = useCallback(
    (parentIds: ID[]) => {
      !createGoalAllowed
        ? openPremiumDialog()
        : openCreateDialog({
            ...(onCreatePresets ?? {}),
            parentIds,
          });
    },
    [createGoalAllowed, onCreatePresets, openCreateDialog, openPremiumDialog],
  );

  const onUpdateGoalProgress = useCallback(
    (id: ID) => {
      const goal = goals.find((goal) => goal.id === id);
      !!goal?.metric && openGoalProgressDialog(id, goal.metric);
    },
    [goals, openGoalProgressDialog],
  );

  return {
    onEditGoal,
    onArchiveGoal,
    onDeleteGoal,
    onCreateGoal,
    onCreateSubGoal,
    onUpdateGoal,
    onUpdateGoalProgress,
  };
};
