import { FirestoreError } from 'firebase/firestore';

export const getLoggableError = (error: Error | unknown) => ({
  url: window.location.href,
  errorCode: (error as FirestoreError)?.code,
  errorMessage: (error as Error)?.message,
  customData: (error as FirestoreError)?.customData,
  error: JSON.stringify(
    error ?? {},
    Object.getOwnPropertyNames(error ?? {}),
    2,
  ),
});
