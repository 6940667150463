import { useCreateHabitAllowed, useDuplicateTask } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyDuplicateObject, Edit3 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { Habit } from 'shared/types/habit';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { focusOnEndOfEditableContent } from 'shared/utils/focus-on-end-of-element';

export type EditListProps = {
  task: Task;
  onClose: () => void;
  nameRef?: React.RefObject<HTMLSpanElement>;
};

export const EditList: React.FC<EditListProps> = ({
  task,
  onClose,
  nameRef,
}) => {
  const { t } = useTranslation();
  const openTaskDetail = useOpenTaskDetail();
  const openHabitDialog = useOpenEditHabitDialog();
  const duplicateTask = useDuplicateTask();
  const isCreateHabitAllowed = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const isDuplicateHabitAllowed =
    isCreateHabitAllowed || task.type === TaskType.Habit;

  const onEdit = () => {
    if ([TaskType.Repeating, TaskType.Habit].includes(task.type)) {
      openHabitDialog(task as Habit, task.id);
      onClose();
      return;
    }

    openTaskDetail(task.id);

    if (nameRef?.current) {
      focusOnEndOfEditableContent(nameRef.current);
    }

    onClose();
  };

  const onDuplicate = () => {
    if (!isDuplicateHabitAllowed) {
      openPremiumDialog();
      onClose();
      return;
    }

    duplicateTask(task);
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
          {t('context-menus.task.labels.edit')}
        </PopupMenuButton>
      </PopupMenuListItem>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onDuplicate}
          start={<Icon icon={CopyDuplicateObject} />}
        >
          {t('context-menus.task.labels.duplicate')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
