import { useUpdateAppToUserSettings } from 'features/user';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';
import { DownloadIosBanner } from 'shared/components/ui/download-ios-banner';
import { CompleteGoalProvider } from 'shared/contexts/complete-goal';
import { CreateGoalTemplateProvider } from 'shared/contexts/create-goal-template';
import { DeleteGoalProvider } from 'shared/contexts/delete-goal';
import { DeleteGoalSectionProvider } from 'shared/contexts/delete-goal-section';
import { DeleteHabitProvider } from 'shared/contexts/delete-habit';
import { DeleteLifeAreaProvider } from 'shared/contexts/delete-life-area';
import { FeedbackDialogProvider } from 'shared/contexts/feedback-dialog';
import { GoalFormProvider } from 'shared/contexts/goal-form';
import { GoalProgressProvider } from 'shared/contexts/goal-progress';
import { GoalTemplatePreviewProvider } from 'shared/contexts/goal-template-preview';
import { HabitFormProvider } from 'shared/contexts/habit-form';
import { HistoryProvider } from 'shared/contexts/history';
import { LifeAreaFormProvider } from 'shared/contexts/life-area-form';
import { PremiumDialogProvider } from 'shared/contexts/premium-dialog';
import { RemoveHabitScheduleProvider } from 'shared/contexts/remove-habit-schedule';
import { RequestPushNotificationsProvider } from 'shared/contexts/request-push-notifications';
import { TaskDetailProvider } from 'shared/contexts/task-detail';
import { UserSettingsProvider } from 'shared/contexts/user-settings';
import { VersionProvider } from 'shared/contexts/version';
import { isIos } from 'shared/utils/is-ios';

import { AppLayout } from './components/app-layout';
import { PagesLayout } from './components/pages-layout';

export const Root: React.FC = () => {
  useUpdateAppToUserSettings();

  return (
    <Suspense>
      <PremiumDialogProvider>
        {isIos() ? (
          <DownloadIosBanner />
        ) : (
          <Suspense>
            <HistoryProvider>
              <RequestPushNotificationsProvider>
                <VersionProvider>
                  <FeedbackDialogProvider>
                    <UserSettingsProvider>
                      <DeleteLifeAreaProvider>
                        <LifeAreaFormProvider>
                          <CreateGoalTemplateProvider>
                            <GoalTemplatePreviewProvider>
                              <CompleteGoalProvider>
                                <DeleteGoalProvider>
                                  <DeleteGoalSectionProvider>
                                    <GoalFormProvider>
                                      <GoalProgressProvider>
                                        <RemoveHabitScheduleProvider>
                                          <DeleteHabitProvider>
                                            <HabitFormProvider>
                                              <AppLayout>
                                                <TaskDetailProvider>
                                                  <PagesLayout>
                                                    <ScrollRestoration />
                                                    <Outlet />
                                                  </PagesLayout>
                                                </TaskDetailProvider>
                                              </AppLayout>
                                            </HabitFormProvider>
                                          </DeleteHabitProvider>
                                        </RemoveHabitScheduleProvider>
                                      </GoalProgressProvider>
                                    </GoalFormProvider>
                                  </DeleteGoalSectionProvider>
                                </DeleteGoalProvider>
                              </CompleteGoalProvider>
                            </GoalTemplatePreviewProvider>
                          </CreateGoalTemplateProvider>
                        </LifeAreaFormProvider>
                      </DeleteLifeAreaProvider>
                    </UserSettingsProvider>
                  </FeedbackDialogProvider>
                </VersionProvider>
              </RequestPushNotificationsProvider>
            </HistoryProvider>
          </Suspense>
        )}
      </PremiumDialogProvider>
    </Suspense>
  );
};
