import { jwtDecode } from 'jwt-decode';
import { redirectWithParameters } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';
import {
  getGoals,
  getUser,
  signInWithToken,
  signOutUser,
} from 'shared/services/backend-api';
import { getLoggableError, getRollbar } from 'shared/services/error-tracking';
import { getSearchParamIdToken } from 'shared/utils/get-search-param-id-token';

export const userLoader = async () => {
  const user = await getUser();

  if (!user) {
    throw redirectWithParameters(Paths.Login);
  }

  return user;
};

export const loginLoader = async () => {
  await signInWithToken();
  const user = await getUser();

  if (user) {
    const goals = await getGoals();
    let extraParams = {};
    // get the queryParameters from the start of login to go with
    try {
      const token = getSearchParamIdToken();
      const { state } = token
        ? jwtDecode<{ state: string }>(token)
        : ({} as { state: undefined });
      extraParams = state ? JSON.parse(state) : {};
    } catch (error) {
      getRollbar().error(
        'parsing searchParams from jwt token failed',
        getLoggableError(error),
      );
    }

    throw redirectWithParameters(
      goals.length ? Paths.Home : Paths.Goals,
      extraParams,
    );
  }

  return user;
};

export const completeSignupLoader = async () => {
  // make sure that if someone has login credentials it is handled
  await signInWithToken();
  const user = await getUser();

  // make sure the user is signed out before continuing
  if (user) {
    await signOutUser();
  }

  return true;
};
