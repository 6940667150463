import { UtmParameters } from 'shared/types/utm-parameters';

export enum QueryParamOptions {
  OobCode = 'oobCode',
  Premium = 'premium',
  Search = 'search',
  C = 'c',
  ExternalId = 'externalId',
}

export const globalQueryParamOptions: (QueryParamOptions | UtmParameters)[] = [
  ...Object.values(QueryParamOptions),
  ...Object.values(UtmParameters),
];
