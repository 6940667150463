import { useMemo } from 'react';
import { useSubscriptionPricesQuery } from 'shared/hooks/queries/use-subscription-prices-query';
import { useUser } from 'shared/hooks/use-user';

export const useSubscriptionPrices = () => {
  const user = useUser();
  const { data } = useSubscriptionPricesQuery(user?.uid);

  return useMemo(() => data ?? { prices: [], discount: null }, [data]);
};
