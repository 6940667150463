import Typography from '@mui/material/Typography';
import { useUserHadTrial } from 'features/user';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useSubscriptionPrices } from 'shared/hooks/use-subscription-prices';
import { Price } from 'shared/types/price';

import { Header } from './header';
import { PaymentOptions } from './payment-options';
import * as Styled from './purchase-premium.style';
import { UspList } from './usp-list';
import { WarningDialog } from './warning-dialog';

export type PurchasePremiumProps = {
  onClose: () => void;
  onSubmit: (price?: Price) => void;
  isLoading?: boolean;
};

export const PurchasePremium: React.FC<PurchasePremiumProps> = ({
  onClose,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  const trialExpired = useUserHadTrial();
  const { prices, discount } = useSubscriptionPrices();
  const [selectedPriceId, setSelectedPriceId] = useState(prices[0]?.id);
  const [warningOpen, setWarningOpen] = useState(false);

  const submitSelection = () => {
    const price = prices.find(({ id }) => id === selectedPriceId);
    onSubmit(price);
  };

  const selectedPrice = useMemo(
    () => prices.find(({ id }) => id === selectedPriceId),
    [prices, selectedPriceId],
  );

  const _onClose = () => {
    if (!!discount && !warningOpen) {
      setWarningOpen(true);
      return;
    }

    setWarningOpen(false);
    onClose();
  };

  const onCancelWarning = () => {
    setWarningOpen(false);
  };

  return (
    <>
      <Styled.Container>
        <Header onClose={_onClose} discountPercentage={discount?.percentage} />

        <Styled.Body>
          <UspList />

          <Styled.PaymentForm>
            <Styled.PaymentOptions>
              <PaymentOptions
                options={prices}
                discount={discount ?? undefined}
                onOption={setSelectedPriceId}
                selectedOption={selectedPriceId}
                disabled={isLoading}
              />
            </Styled.PaymentOptions>

            <Styled.Interactions>
              <Button
                variant={Variants.Contained}
                size={Sizes.Large}
                color={Colors.Primary}
                onClick={submitSelection}
                disabled={isLoading || !selectedPrice}
                fullWidth
                alignCenter
                disabledBackgroundOnly
              >
                {isLoading ? (
                  <Styled.Loader color="primary" size={20} />
                ) : (
                  t(
                    trialExpired
                      ? 'dialogs.premium.interactions.confirm.premium'
                      : 'dialogs.premium.interactions.confirm.trial',
                  )
                )}
              </Button>
              {!!selectedPrice && (
                <Typography variant="overline">
                  {t('dialogs.premium.interactions.disclaimer.title')}
                </Typography>
              )}
            </Styled.Interactions>
          </Styled.PaymentForm>
        </Styled.Body>
      </Styled.Container>
      <WarningDialog
        open={warningOpen}
        onSubmit={onCancelWarning}
        onClose={_onClose}
      />
    </>
  );
};
