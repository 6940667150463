import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKey as useUserKey } from 'shared/hooks/queries/use-user-query';
import {
  getEmailByOobcode,
  resetPassword,
  signInUser,
} from 'shared/services/backend-api';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

export const useResetPasswordMutation = () => {
  const client = useQueryClient();

  return useMutation<
    void,
    ResetPasswordErrorTypes,
    {
      password: string;
      code: string;
    }
  >({
    mutationFn: async ({ password, code }) => {
      // get email from the oobCode
      const email = await getEmailByOobcode(code);
      // reset password
      await resetPassword(code, password);
      // login user
      await signInUser(email, password);
      // invalidate user
      await client.invalidateQueries({ queryKey: [useUserKey] });
    },
  });
};
