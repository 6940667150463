import { doc, getDoc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { lifeAreaConverter } from './converters/life-area';
import { CollectionOptions, getDatabase } from './helpers';

export const getLifeAreaById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.LifeAreas, id).withConverter(
    lifeAreaConverter,
  );
};

export const getLifeAreaDocById = async (id: ID) => {
  const query = await getLifeAreaById(id);
  const snapshot = await getDoc(query);
  return snapshot.data();
};
