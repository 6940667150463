import { useCustomLifeAreas } from 'features/life-areas';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

export const useCreateCustomLifeAreasAllowed = () => {
  const lifeAreas = useCustomLifeAreas();
  const { allowed, enforced, limit } = usePremiumFeatureAllowed(
    LimitKeys.CustomLifeAreas,
    {
      totalCustom: lifeAreas.length,
    },
  );

  return {
    allowed,
    enforced,
    limit,
    total: lifeAreas.length,
  };
};
