import { useCallback } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Locales } from 'shared/assets/localization';
import { useSignupUserMutation } from 'shared/hooks/queries/use-signup-user-mutation';

export const useSignup = () => {
  const { revalidate } = useRevalidator();
  const { mutateAsync, reset, isPending, error } = useSignupUserMutation();

  const signup = useCallback(
    async ({
      name,
      email,
      password,
      locale,
    }: {
      name?: string;
      email: string;
      password: string;
      locale?: Locales;
    }) => {
      await mutateAsync({ name, email, password, locale });
      revalidate();
    },
    [mutateAsync, revalidate],
  );

  return { signup, error, retry: reset, isLoading: isPending };
};
