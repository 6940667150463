import { typographyToCss } from 'shared/lib/mui';
import { TaskPriorityOptions } from 'shared/types/task-base';
import styled, { DefaultTheme } from 'styled-components';

const getColor = (
  priority: TaskPriorityOptions | null,
  theme: DefaultTheme,
) => {
  switch (priority) {
    case TaskPriorityOptions.Low:
      return theme.palette.info.main;
    case TaskPriorityOptions.Medium:
      return theme.palette.warning.main;
    case TaskPriorityOptions.High:
      return theme.palette.error.dark;
    default:
      return theme.palette.divider;
  }
};

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(1)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.default};
  transition: background-color 200ms;
`;

export const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

export const CheckboxPlaceholder = styled.div<{
  $priority: TaskPriorityOptions | null;
}>`
  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  min-height: 1.6rem;
  margin: ${({ theme }) => theme.spacing(1)};

  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 0.2rem solid ${({ $priority, theme }) => getColor($priority, theme)};
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(6.5)}`};
`;

export const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li``;

export const MetaTag = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1.5)}`};

  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
