import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, MoreHorizontal } from 'shared/assets/icons';
import { GoalSectionContextMenu } from 'shared/components/connected/goal-section-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';
import { isOtherSection } from 'shared/utils/goal-section-id-helpers';
import { useTheme } from 'styled-components';

import * as Styled from './goal-section-header.style';

export type GoalSectionHeaderProps = {
  id: ID;
  goalId: ID;
  name: string;
  taskCount?: number;
  isFrozen?: boolean;
  nameRef?: React.RefObject<HTMLInputElement>;

  onUpdateSection?: (id: ID, name: string) => void;
  onPremium: () => void;
  onOpen: () => void;
  isOpen?: boolean;
  isDragging?: boolean;
  isMoving?: boolean;
  isHovered?: boolean;
  isPreview?: boolean;
};

export const GoalSectionHeader: React.FC<GoalSectionHeaderProps> = ({
  id,
  goalId,
  name,
  taskCount,
  isFrozen,
  nameRef,

  onUpdateSection,
  onPremium,
  onOpen,
  isOpen,
  isDragging,
  isMoving,
  isPreview,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useForwardedRef(nameRef);
  const [updatedName, setUpdatedName] = useState(name);

  const [menuPosition, setMenuPosition] = useState<
    { left: number; top: number } | undefined
  >(undefined);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onUnfold = () => {
    if (isFrozen) {
      onPremium();
      return;
    }
    onOpen?.();
  };

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isPreview) {
      return;
    }

    setMenuPosition({ left: e.clientX, top: e.clientY });
    openMenu();
  };

  const onMoreButton = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuPosition(undefined);
    openMenu();
  };

  const onChangeName = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setUpdatedName(target.value);
  const onBlurName = () =>
    updatedName !== name && onUpdateSection?.(id, updatedName);

  useEffect(() => {
    // update name when an update comes from outside
    setUpdatedName(name);
  }, [name]);

  useKeysDown(['Enter'], (e) => {
    if (document.activeElement === inputRef.current) {
      // prevent 'enter' in the section name
      e.preventDefault();

      inputRef.current?.blur();
      onUpdateSection?.(id, updatedName);
    }
  });

  return (
    <>
      <Styled.Container
        onContextMenu={
          !isOtherSection(id) && !theme.isMobile ? onContextMenu : undefined
        }
        $isDragging={!!isDragging}
        $isMoving={!!isMoving}
      >
        <Styled.UnfoldButton onClick={onUnfold}>
          <Styled.UnfoldIconContainer $open={!!isOpen}>
            <Icon icon={ChevronLeft} />
          </Styled.UnfoldIconContainer>
        </Styled.UnfoldButton>

        <Styled.Body>
          <Styled.Content>
            {!isPreview && !isOtherSection(id) ? (
              <ResizableInput
                ref={inputRef}
                value={updatedName}
                onChange={onChangeName}
                onBlur={onBlurName}
                minWidth={'0.2rem'}
                placeholder={t(
                  'general.labels.goal.sections.placeholder.label',
                )}
              />
            ) : (
              <Styled.OtherSectionTitle>{name}</Styled.OtherSectionTitle>
            )}
            {typeof taskCount === 'number' && (
              <Styled.TaskCount>{taskCount}</Styled.TaskCount>
            )}
          </Styled.Content>
          {!isPreview && !isOtherSection(id) && (
            <Styled.IconButtonContainer>
              <IconButton
                ref={buttonRef}
                icon={MoreHorizontal}
                onClick={onMoreButton}
                size={Sizes.Medium}
              />
            </Styled.IconButtonContainer>
          )}
        </Styled.Body>
      </Styled.Container>

      {menuOpen && !theme.isMobile && (
        <GoalSectionContextMenu
          id={id}
          goalId={goalId}
          inputRef={inputRef}
          referenceElement={menuPosition ? undefined : buttonRef}
          location={menuPosition}
          onClose={closeMenu}
        />
      )}
    </>
  );
};
