import React, { forwardRef } from 'react';
import { Plus } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './mobile-add-button.style';

export type MobileAddButtonProps = Omit<
  React.ComponentProps<'button'>,
  'onClick'
> & {
  onClick?: (e: React.MouseEvent) => void;
};

export const MobileAddButton = forwardRef<
  HTMLButtonElement,
  MobileAddButtonProps
>(({ onClick, ...rest }, ref) => {
  const _onClick = (e: React.MouseEvent) => {
    onClick?.(e);
  };

  return (
    <Styled.Button onClick={_onClick} ref={ref} {...rest}>
      <Icon icon={Plus} />
    </Styled.Button>
  );
});
