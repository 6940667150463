import { useCreateHabitAllowed } from 'features/tasks';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { DataType } from 'shared/types/data-type';

import * as Styled from './mobile-add-task-button.style';

export const MobileAddTaskButton = () => {
  const createHabit = useOpenCreateHabitDialog();
  const openPremiumDialog = useOpenPremiumDialog();
  const createHabitsAllowed = useCreateHabitAllowed();

  const onCreate = () =>
    createHabitsAllowed ? createHabit() : openPremiumDialog();

  return (
    <Styled.Container>
      <MobileAddButton onClick={onCreate} data-component-type={DataType.Task} />
    </Styled.Container>
  );
};
