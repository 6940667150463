import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UtmParameters } from 'shared/types/utm-parameters';

import { QueryParamOptions } from './globally-allowed-query-parameters';

export const useQueryParam = (
  key: QueryParamOptions | UtmParameters,
  initialValue?: string | number,
): [string | undefined, (value?: string | number) => void] => {
  const [queryParams, setQueryParams] = useSearchParams();
  const queryParam = queryParams.get(key) ?? undefined;

  const setQueryParam = useCallback(
    (value?: string | number, replace?: boolean) =>
      setQueryParams(
        (oldQueryParams) => {
          oldQueryParams.set(key, String(value));

          if (!value) {
            oldQueryParams.delete(key);
          }

          return oldQueryParams;
        },
        { replace },
      ),
    [key, setQueryParams],
  );

  useEffect(() => {
    if (!queryParam && typeof initialValue !== 'undefined') {
      setQueryParam(initialValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on the first render the initialValue should be set.
  }, []);

  return [queryParam, setQueryParam];
};

export const useQueryParamArray = (
  key: QueryParamOptions | UtmParameters,
): [string[], (value?: string[] | number[]) => void] => {
  const [queryParams, setQueryParams] = useSearchParams();
  const queryParam = queryParams.getAll(key);

  const setQueryParam = useCallback(
    (value?: string[] | number[]) =>
      setQueryParams((oldQueryParams) => {
        oldQueryParams.delete(key);
        value?.forEach((val) => {
          oldQueryParams.append(key, String(val));
        });

        return oldQueryParams;
      }),
    [key, setQueryParams],
  );

  return [queryParam, setQueryParam];
};

export const useQueryParams = (
  keys: (QueryParamOptions | UtmParameters)[],
): [Record<string, string>, (value?: Record<string, string>) => void] => {
  const [queryParams, setQueryParams] = useSearchParams();

  const selectedQueryParams = useMemo(
    () =>
      keys.reduce<Record<string, string>>((acc, key) => {
        const val = queryParams.get(key);
        if (val) {
          acc[key] = val;
        }

        return acc;
      }, {}),
    [keys, queryParams],
  );

  const setSelectedQueryParams = useCallback(
    (value: Record<string, string> = {}) =>
      setQueryParams((oldQueryParams) => {
        keys.forEach((key) => {
          const val = value[key];
          oldQueryParams.set(key, val);
          if (!val) {
            oldQueryParams.delete(key);
          }
        });

        return oldQueryParams;
      }),
    [keys, setQueryParams],
  );

  return [selectedQueryParams, setSelectedQueryParams];
};
