import { useGoalById } from 'features/goals';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ArrowLeft, MoreHorizontal, Star } from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import {
  Button,
  Colors,
  Sizes as ButtonSizes,
  Variants,
} from 'shared/components/ui/button';
import { GridContainer } from 'shared/components/ui/grid-container';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useBackFunction } from 'shared/contexts/history';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { FavoriteType } from 'shared/types/user-settings';

import { GoalDetailIllustration } from '../goal-detail-illustration';
import * as Styled from './detail-navigation.style';

export const DetailNavigation: React.FC = () => {
  const interactionsRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const { id } = useParams();
  const goal = useGoalById(id);
  const isFavorite = useIsFavorite(id);

  const backFunction = useBackFunction(Paths.Goals);
  const toggleFavorite = useToggleFavorite();

  const onFavorite = () => {
    !!goal && toggleFavorite(goal.id, FavoriteType.Goal);
  };

  return (
    <Styled.Container $hasImage={!!goal?.image}>
      {!!goal?.image && (
        <Styled.MobileImage>
          <GoalDetailIllustration illustration={goal.image} />
        </Styled.MobileImage>
      )}

      <GridContainer>
        <Styled.ButtonsContainer>
          <Styled.MobileBackButtonContainer $hasImage={!!goal?.image}>
            <IconButton
              icon={ArrowLeft}
              size={Sizes.Large}
              onClick={backFunction}
            />
          </Styled.MobileBackButtonContainer>
          <Styled.DesktopBackButtonContainer>
            <Button
              onClick={backFunction}
              startIcon={ArrowLeft}
              size={ButtonSizes.Medium}
              variant={Variants.Text}
              color={Colors.Inherit}
            >
              {t('general.labels.navigation.back.title')}
            </Button>
          </Styled.DesktopBackButtonContainer>

          <Styled.Interactions ref={interactionsRef}>
            <Styled.FavoriteButtonContainer $isFavorite={isFavorite}>
              <IconButton icon={Star} size={Sizes.Large} onClick={onFavorite} />
            </Styled.FavoriteButtonContainer>

            <Styled.MoreButtonContainer $hasImage={!!goal?.image}>
              <IconButton
                icon={MoreHorizontal}
                size={Sizes.Large}
                onClick={openMenu}
              />
            </Styled.MoreButtonContainer>
            {menuOpen && (
              <GoalContextMenu
                id={id!}
                onClose={closeMenu}
                referenceElement={interactionsRef}
              />
            )}
          </Styled.Interactions>
        </Styled.ButtonsContainer>
      </GridContainer>
    </Styled.Container>
  );
};
