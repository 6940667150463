import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './empty-goals.style';

export type EmptyGoalsProps = {
  onAdd: (e: React.MouseEvent) => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
};

export const EmptyGoals: React.FC<EmptyGoalsProps> = ({ onAdd, buttonRef }) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.ImageContainer>
        <Styled.Image
          src="/images/rocket/rocket-graphic.png"
          srcSet="/images/rocket/rocket-graphic@2x.png, /images/rocket/rocket-graphic@3x.png"
        />
      </Styled.ImageContainer>

      <Styled.Body>
        <Styled.Content>
          <Typography variant="h4">{t('pages.goals.empty.title')}</Typography>
          <Styled.Description>
            {t('pages.goals.empty.description')}
          </Styled.Description>
        </Styled.Content>
        <Styled.Interactions>
          <Button
            ref={buttonRef}
            variant={Variants.Contained}
            color={Colors.White}
            size={Sizes.Medium}
            onClick={onAdd}
          >
            {t('pages.goals.empty.button.add.label')}
          </Button>
        </Styled.Interactions>
      </Styled.Body>
    </Styled.Container>
  );
};
