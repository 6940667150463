import { addDays } from 'date-fns';
import React, { useMemo } from 'react';
import {
  Flag,
  IconFormIcons,
  IconFormNames,
  Target,
} from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { TimeLeft } from 'shared/components/ui/time-left';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

import * as Styled from './goal-template-preview-header.style';

export type GoalTemplatePreviewHeaderProps = {
  name: string;
  iconName: IconFormNames | null;
  lifeAreaTemplateId: ID | null;
  daysTillDeadline: number | null;

  lifeAreas: LifeArea[];
};

export const GoalTemplatePreviewHeader: React.FC<
  GoalTemplatePreviewHeaderProps
> = ({ name, iconName, lifeAreaTemplateId, daysTillDeadline, lifeAreas }) => {
  const today = useToday();

  const lifeArea = useMemo(
    () => lifeAreas.find(({ templateId }) => templateId === lifeAreaTemplateId),
    [lifeAreaTemplateId, lifeAreas],
  );

  const deadline = useMemo(
    () =>
      typeof daysTillDeadline === 'number'
        ? addDays(today, daysTillDeadline)
        : undefined,
    [daysTillDeadline, today],
  );

  return (
    <Styled.Container>
      <Styled.Illustration>
        <Icon icon={iconName ? IconFormIcons[iconName] ?? Target : Target} />
      </Styled.Illustration>

      <Styled.Content>
        <Styled.Title>{name}</Styled.Title>

        <Styled.MetaContainer>
          {lifeArea && (
            <Styled.MetaTag>
              <Styled.MetaIcon>
                <Icon icon={IconFormIcons[lifeArea.iconName]} />
              </Styled.MetaIcon>

              {lifeArea.name}
            </Styled.MetaTag>
          )}

          {!!deadline && (
            <Styled.MetaTag>
              <TimeLeft startIcon={Flag} date={deadline} />
            </Styled.MetaTag>
          )}

          <Styled.MetaTag>
            <CircleProgress percentage={0} />
          </Styled.MetaTag>
        </Styled.MetaContainer>
      </Styled.Content>
    </Styled.Container>
  );
};
