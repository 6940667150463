import { httpsCallable } from 'firebase/functions';
import { Discount, Price, PriceInterval } from 'shared/types/price';

import { getFunctions } from './helpers';

const intervalOrder = [PriceInterval.Month, PriceInterval.Year];

export const getSubscriptionPrices = async () => {
  const functions = getFunctions();
  const getPrices = httpsCallable<
    undefined,
    { prices: Price[]; discount: Discount | null }
  >(functions, 'stripe-getPricesForUser');

  const { data } = await getPrices();

  const sortedPrices = data.prices.sort((priceA, priceB) => {
    const priceAIndex = intervalOrder.indexOf(priceA.interval);
    const priceBIndex = intervalOrder.indexOf(priceB.interval);

    if (priceAIndex === -1) {
      return 1;
    }

    if (priceBIndex === -1) {
      return -1;
    }

    return priceBIndex - priceAIndex;
  });

  return { prices: sortedPrices, discount: data.discount };
};
