import React, { Suspense, useRef } from 'react';
import { DotsMenu } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';

import { ContextMenu } from './context-menu';

export type TaskMoreButtonProps = {
  taskId?: ID;
};

export const TaskMoreButton: React.FC<TaskMoreButtonProps> = ({ taskId }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  useClickOutside(menuRef, closeMenu);

  return (
    <>
      <IconButton
        icon={DotsMenu}
        size={Sizes.Large}
        ref={buttonRef}
        onClick={openMenu}
        preventDrag
      />

      <Suspense>
        <ContextMenu
          ref={menuRef}
          taskId={taskId}
          onClose={closeMenu}
          open={menuOpen}
          referenceElement={buttonRef}
        />
      </Suspense>
    </>
  );
};
