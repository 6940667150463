import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

export const useCreateSubGoalAllowed = () => {
  const goals = useGoals();

  const filteredGoals = useMemo(
    () =>
      goals.filter(
        ({ completedAt, parentIds, excludedFromLimits }) =>
          !excludedFromLimits && parentIds?.length && !completedAt,
      ),
    [goals],
  );

  const { allowed, enforced, limit } = usePremiumFeatureAllowed(
    LimitKeys.SubGoals,
    {
      totalUncompleted: filteredGoals.length,
    },
  );

  return {
    allowed,
    enforced,
    limit,
    total: filteredGoals.length,
  };
};
