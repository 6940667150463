import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { NewSubGoalRow } from 'shared/components/ui/new-sub-goal-row';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';
import {
  getOtherSectionId,
  isOtherSection,
} from 'shared/utils/goal-section-id-helpers';
import { sortItems } from 'shared/utils/sort-items';

import { GoalSectionsList } from './goal-sections-list';
import { GoalsList } from './goals-list';
import * as Styled from './task-goal-section-list.style';
import { TasksList } from './tasks-list';

export type GoalSectionTaskListProps = Omit<
  React.ComponentProps<typeof GoalsList>,
  'goals' | 'parentId'
> &
  Omit<
    React.ComponentProps<typeof GoalSectionsList>,
    'sections' | 'goalId' | 'isFrozen'
  > &
  Omit<React.ComponentProps<typeof TasksList>, 'tasks'> & {
    goalId: ID;
    sectionId?: ID;
    newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
    onCreateSubGoal?: () => void;
    canCreateTask?: boolean;
  };

export const GoalSectionTaskList: React.FC<GoalSectionTaskListProps> = ({
  goalId,
  sectionId,
  newTaskInputRef,
  onCreateSubGoal,
  canCreateTask,

  allGoals,
  lifeAreas,
  onUpdateGoalProgress,
  onPremium,
  onUpdateSection,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  selectedItem,
  canCollapse,
  initialOpen,
  noRightSpacing,

  items,
  hasDnd,
}) => {
  const { t } = useTranslation();

  const goal = useMemo(
    () => items.find(({ id }) => id === goalId) as Goal | undefined,
    [goalId, items],
  );
  const section = useMemo(
    () =>
      sectionId
        ? goal?.sections?.find((section) => section.id === sectionId)
        : undefined,
    [goal, sectionId],
  );

  const ownGoals = useMemo(
    () =>
      !sectionId
        ? sortItems(
            items.filter(
              (item) =>
                !('fields' in item) &&
                item.parentIds?.[item.parentIds.length - 1] === goalId,
            ) as Goal[],
            goal?.goalSortingMode === GoalSortingMode.Custom
              ? goal?.goalSorting ?? []
              : [],
            'id',
          )
        : [],
    [goal?.goalSorting, goal?.goalSortingMode, goalId, items, sectionId],
  );

  const ownTasks = useMemo(
    () =>
      sortItems(
        items.filter((item) => {
          // filter out non-tasks
          if (!('fields' in item)) {
            return false;
          }
          // filter out tasks that are not part of this goal
          if (item.fields?.goalId !== goalId) {
            return false;
          }
          // if `other` section, take everything that is not in sections
          if (sectionId && isOtherSection(sectionId)) {
            return !goal?.sections?.some(({ tasks }) =>
              tasks.includes(item.id),
            );
          }
          return section ? section.tasks.includes(item.id) : true;
        }) as TaskCardTask[],
        section?.tasks ?? goal?.taskSorting ?? [],
        'id',
      ),
    [goal?.sections, goal?.taskSorting, goalId, items, section, sectionId],
  );

  const ownSections = useMemo(
    () =>
      !sectionId && goal?.sections?.length
        ? [
            ...goal.sections,
            {
              id: getOtherSectionId(goalId),
              tasks: sortItems(
                ownTasks.filter(
                  ({ id }) =>
                    !goal.sections?.find(({ tasks }) => tasks.includes(id)),
                ),
                goal.taskSorting ?? [],
                'id',
              ).map(({ id }) => id),
              name: t('general.labels.goal.sections.other.label'),
              createdAt: new Date(),
            },
          ]
        : [],
    [goal?.sections, goal?.taskSorting, goalId, ownTasks, sectionId, t],
  );

  return (
    <>
      {!!ownGoals.length && (
        <GoalsList
          goals={ownGoals}
          items={items}
          allGoals={allGoals}
          lifeAreas={lifeAreas}
          onUpdateGoalProgress={onUpdateGoalProgress}
          onUpdateSection={onUpdateSection}
          onTask={onTask}
          onUnfocus={onUnfocus}
          hideGoal={hideGoal}
          showRepeatingAsTodo={showRepeatingAsTodo}
          selectedItem={selectedItem}
          canCreateTask={canCreateTask}
          canCollapse={canCollapse}
          initialOpen={initialOpen}
          onPremium={onPremium}
          hasDnd={hasDnd}
          noRightSpacing={noRightSpacing}
        />
      )}
      {!!ownSections.length && (
        <GoalSectionsList
          goalId={goalId}
          sections={ownSections}
          items={items}
          allGoals={allGoals}
          lifeAreas={lifeAreas}
          onPremium={onPremium}
          onUpdateSection={onUpdateSection}
          onTask={onTask}
          onUnfocus={onUnfocus}
          hideGoal={hideGoal}
          showRepeatingAsTodo={showRepeatingAsTodo}
          selectedItem={selectedItem}
          isFrozen={!!goal?.frozenAt}
          canCreateTask={canCreateTask}
          canCollapse={canCollapse}
          initialOpen={initialOpen}
          hasDnd={hasDnd}
          noRightSpacing={noRightSpacing}
        />
      )}
      {!ownSections.length && (
        <TasksList
          tasks={ownTasks}
          showRepeatingAsTodo={showRepeatingAsTodo}
          selectedItem={selectedItem}
          onTask={onTask}
          onUnfocus={onUnfocus}
          hideGoal={hideGoal}
          hasIndentation={!sectionId}
          hasDnd={hasDnd}
          noRightSpacing={noRightSpacing}
        />
      )}

      {(!!onCreateSubGoal || !!canCreateTask) && (
        <Styled.DroppableArea>
          {!!canCreateTask && !ownSections.length && (
            <Styled.NewTaskContainer $hasIndentation={!!sectionId}>
              <NewTaskRow
                goal={goalId}
                section={sectionId}
                newTaskInputRef={newTaskInputRef}
              />
            </Styled.NewTaskContainer>
          )}
          {!!onCreateSubGoal && (
            <Styled.NewTaskContainer $hasIndentation={!!sectionId}>
              <NewSubGoalRow onCreateGoal={onCreateSubGoal} />
            </Styled.NewTaskContainer>
          )}
        </Styled.DroppableArea>
      )}
    </>
  );
};
