import { useCallback } from 'react';
import { useDuplicateTasksMutation } from 'shared/hooks/queries/use-duplicate-tasks-mutation';
import { getTaskDocs } from 'shared/services/backend-api/get-tasks';
import { Task } from 'shared/types/task';
import { chunkArray } from 'shared/utils/chunk-array';

import { updateLinkedGoal } from './update-linked-goal';
import { updateLinkedLifeArea } from './update-linked-life-area';

export const useDuplicateTask = () => {
  const { mutateAsync: duplicateTasks } = useDuplicateTasksMutation();

  return useCallback(
    async (task: Task) => {
      // get subtasks, if any
      const subtasks = task.childIds?.length
        ? (
            await Promise.all(
              chunkArray(task.childIds, 30).map(
                async (chunkedSubTaskIds) =>
                  await getTaskDocs({ ids: chunkedSubTaskIds }),
              ),
            )
          ).flat(1)
        : [];

      const newTasks = await duplicateTasks([task, ...subtasks]);
      const newMainTask = newTasks.find(({ parentIds }) => !parentIds)!;

      await updateLinkedGoal(task, newMainTask);
      await updateLinkedLifeArea(task, newMainTask);
    },
    [duplicateTasks],
  );
};
