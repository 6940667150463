import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import React, { useRef } from 'react';
import { AddGoalContextMenu } from 'shared/components/connected/add-goal-context-menu';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';

import * as Styled from './mobile-add-goal-button.style';

export const MobileAddGoalButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const openPremiumDialog = useOpenPremiumDialog();
  const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();

  const { position, open, close } = useContextMenuState();

  const onAdd = (e: React.MouseEvent) =>
    createGoalAllowed ? open(e) : openPremiumDialog();

  useClickOutside(containerRef, close);

  return (
    <Styled.Container ref={containerRef}>
      <MobileAddButton ref={buttonRef} onClick={onAdd} />

      {!!position && (
        <AddGoalContextMenu
          ref={contextMenuRef}
          referenceElement={buttonRef}
          position="top-end"
          onClose={close}
        />
      )}
    </Styled.Container>
  );
};
