import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  container-type: inline-size;
  width: 100%;
  height: 100%;
`;

export const Content = styled.button`
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(4)};
  margin: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  text-align: left;

  @container (min-width: 222px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Illustration = styled.div<{ $color: string | null | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme, $color }) =>
    $color ? rgba($color, 0.2) : theme.palette.warning.light};
  aspect-ratio: 1;

  color: ${({ theme, $color }) => $color ?? theme.palette.warning.dark};
  font-size: 2rem;
`;

export const Body = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  margin-top: auto;

  @container (min-width: 222px) {
    display: none;
  }
`;

export const MetaContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  max-width: 100%;
  min-width: 0;
`;
