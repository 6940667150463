import styled, { css } from 'styled-components';

export const List = styled.ul<{ $noRightSpacing: boolean }>`
  list-style: none;

  margin: 0;
  padding: ${({ theme, $noRightSpacing }) =>
    `0 ${$noRightSpacing ? 0 : theme.spacing(1)} 0 ${theme.spacing(1)}`};
`;

export const ListItem = styled.li<{ $hasIndentation?: boolean }>`
  ${({ $hasIndentation, theme }) =>
    $hasIndentation &&
    !theme.isMobile &&
    css`
      padding-left: ${theme.spacing(4)};
    `};
`;

export const SublistContainer = styled.div<{
  $minimalIndentation?: boolean;
  $maximalIndentation?: boolean;
}>`
  padding-left: ${({ theme, $minimalIndentation, $maximalIndentation }) =>
    theme.spacing($minimalIndentation ? 3 : $maximalIndentation ? 7 : 6)};
`;
