import { useQuery } from '@tanstack/react-query';
import { createSubscriptionDoc } from 'shared/lib/@tanstack-query';
import { getUserRef } from 'shared/services/backend-api';

export const queryKey = 'user';

export const useUserQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: async (context) => {
      const userRef = await getUserRef();

      if (!userRef) {
        return null;
      }

      return createSubscriptionDoc(() => userRef, {
        setTanstackError: false,
      })(context);
    },
  });
