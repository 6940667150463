import { useCreateCustomLifeAreasAllowed } from 'features/life-areas';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateLifeAreaDialog } from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';

import * as Styled from './mobile-add-life-area-button.style';

export const MobileAddLifeAreaButton = () => {
  const openCreateDialog = useOpenCreateLifeAreaDialog();
  const openPremiumDialog = useOpenPremiumDialog();
  const { allowed: createLifeAreaAllowed } = useCreateCustomLifeAreasAllowed();

  const onCreate = () =>
    createLifeAreaAllowed ? openCreateDialog() : openPremiumDialog();

  return (
    <Styled.Container>
      <MobileAddButton onClick={onCreate} />
    </Styled.Container>
  );
};
