import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;
