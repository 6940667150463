import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;
