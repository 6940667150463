import { useCreateUnlimitedGoalsAllowed, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useUpdateGoalOrder,
} from 'features/user';
import React, { useMemo } from 'react';
import { SelfSortingGoalDataGrid } from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useToday } from 'shared/contexts/today';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';
import { useTheme } from 'styled-components';

import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Archived: React.FC = () => {
  const theme = useTheme();
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(GoalRoadmapSortingKeys.InProgress);
  const favoriteIds = useFavoriteIds();

  const updateOrder = useUpdateGoalOrder(GoalRoadmapSortingKeys.Archived);
  const openCreateDialog = useOpenCreateGoalDialog();

  const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.Archived](goals, today)
          .filter(({ parentIds }) => !parentIds?.length)
          .sort(
            (goalA, goalB) =>
              goalB.archivedAt!.getTime() - goalA.archivedAt!.getTime(),
          ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  return (
    <GridContainer>
      <TabPanel role="tab-panel">
        <SelfSortingGoalDataGrid
          goals={filteredGoals}
          allGoals={goals}
          lifeAreas={lifeAreas}
          favorites={favoriteIds}
          onReorder={updateOrder}
          onCreateGoal={
            !theme.isMobile && createGoalAllowed ? openCreateDialog : undefined
          }
          isArchived
        />
      </TabPanel>
    </GridContainer>
  );
};
