import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sizes, Variants } from 'shared/components/ui/button';
import { GridContainer } from 'shared/components/ui/grid-container';
import { Paths } from 'shared/routes';

import * as Styled from './not-found.style';

export type NotFoundProps = {
  errors: string[];
};

export const NotFound: React.FC<NotFoundProps> = ({ errors }) => {
  const { t } = useTranslation();

  const navigateToToday = () => {
    window.location.href = Paths.Home;
  };

  const onContact = () => {
    window.location.href = `mailto:support@griply.app?body=Please don't remove this line "${errors.join(', ')}".`;
  };

  return (
    <Styled.Container>
      <GridContainer>
        <Styled.ContentContainer>
          <Styled.Content>
            <Styled.Body>
              <Typography variant="h1" component="span">
                {t('pages.not-found.title')}
              </Typography>
              <Typography variant="h5" component="span">
                {t('pages.not-found.subtitle')}
              </Typography>
              <Typography variant="body2" component="span">
                {t('pages.not-found.description')}
              </Typography>
            </Styled.Body>

            {errors.map((errorId) => (
              <Styled.IssueLabel>{errorId}</Styled.IssueLabel>
            ))}
          </Styled.Content>

          <Styled.Interactions>
            <Button onClick={navigateToToday} size={Sizes.Medium}>
              {t('pages.not-found.interactions.back-home.label')}
            </Button>
            <Button
              onClick={onContact}
              variant={Variants.Text}
              size={Sizes.Medium}
            >
              {t('pages.not-found.interactions.contact-support.label')}
            </Button>
          </Styled.Interactions>
        </Styled.ContentContainer>
      </GridContainer>
    </Styled.Container>
  );
};
