import React, { useRef } from 'react';
import { MoreHorizontal } from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';

import * as Styled from './goal-data-grid.style';

export type MenuCellProps = {
  id: ID;
  isFrozen?: boolean;
};

export const MenuCell: React.FC<MenuCellProps> = ({ id, isFrozen }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onButton = (e: React.MouseEvent) => {
    e.preventDefault();
    openMenu();
  };

  return (
    <Styled.DataCell $isFrozen={!!isFrozen} $hasButton>
      <IconButton
        ref={buttonRef}
        icon={MoreHorizontal}
        onClick={!isFrozen ? onButton : undefined}
        size={Sizes.Small}
      />

      {menuOpen && (
        <GoalContextMenu
          referenceElement={buttonRef}
          onClose={closeMenu}
          id={id}
        />
      )}
    </Styled.DataCell>
  );
};
