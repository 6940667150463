import { redirect } from 'react-router-dom';

import { getGlobalAllowedSearchParameters } from './get-global-allowed-search-parameters';

export const redirectWithParameters = (
  redirectUrl: string,
  extraParameters: {} = {},
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const extraSearchParams = new URLSearchParams(extraParameters);
  const searchParams = new URLSearchParams([
    ...urlSearchParams,
    ...extraSearchParams,
  ]);
  const queryParams = getGlobalAllowedSearchParameters(searchParams);
  return redirect(`${redirectUrl}?${queryParams.toString()}`);
};
