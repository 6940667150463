import styled, { css } from 'styled-components';

export const Container = styled.div`
  container-type: inline-size;
  margin: ${({ theme }) => `0 ${theme.spacing(-6)}`};
`;

const TitleStyle = css`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  color: inherit;
`;

export const HeaderContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Header = styled.button`
  ${TitleStyle};
  position: relative;
  margin: 0;

  background: transparent;
  border: none;

  cursor: pointer;
  line-height: 1;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
    `};
`;

export const IconButtonContainer = styled.span`
  position: absolute;
  left: ${({ theme }) => theme.spacing(-5.5)};
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      left: unset;
      right: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const EmblaContainer = styled.div`
  overflow: hidden;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(6)}`};
`;

export const EmblaList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const EmblaListItem = styled.li`
  flex: 0 0 80%;
  max-width: 80%;
`;

export const EmblaSubList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};

  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};

  margin: 0;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(6)}`};
  width: 100%;

  list-style: none;

  @container (min-width: 456px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: 560px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @container (min-width: 686px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ListItem = styled.li``;
