import { useMutation } from '@tanstack/react-query';
import { createStripeSessionUrl } from 'shared/services/backend-api';
import { PriceInterval } from 'shared/types/price';

export const useStripeSessionUrlMutation = () =>
  useMutation({
    mutationFn: ({
      interval,
      discountCode,
    }: {
      interval: PriceInterval;
      discountCode?: string;
    }) => createStripeSessionUrl({ interval, c: discountCode }),
  });
