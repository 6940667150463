import { useCreateUnlimitedGoalsAllowed, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useUpdateGoalOrder,
  useUserHadTrial,
} from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoalDataGrid,
  SelfSortingGoalDataGrid,
} from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';
import { sortItems } from 'shared/utils/sort-items';
import { useTheme } from 'styled-components';

import { EmptyBanner } from './components/empty-banner';
import { TabPanel } from './components/tab-panel';
import * as Styled from './goals-overview.style';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const ThisYear: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const today = useToday();
  const year = today.getFullYear().toString();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(year);
  const favoriteIds = useFavoriteIds();
  const userHadTrial = useUserHadTrial();

  const updateOrder = useUpdateGoalOrder(year);
  const openCreateDialog = useOpenCreateGoalDialog();

  const openPremiumDialog = useOpenPremiumDialog();
  const {
    allowed: createGoalAllowed,
    enforced: createGoalEnforced,
    limit: createGoalLimit,
    total: createGoalTotal,
  } = useCreateUnlimitedGoalsAllowed();

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.ThisYear](goals, today).filter(
          ({ parentIds }) => !parentIds?.length,
        ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  const activeGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !frozenAt),
    [filteredGoals],
  );
  const frozenGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !!frozenAt),
    [filteredGoals],
  );

  const showPremiumBanner =
    !createGoalAllowed ||
    (!createGoalEnforced && createGoalLimit < createGoalTotal);

  return (
    <GridContainer>
      {!activeGoals.length ? (
        <EmptyBanner />
      ) : (
        <TabPanel role="tab-panel">
          <SelfSortingGoalDataGrid
            goals={activeGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onReorder={updateOrder}
            onCreateGoal={
              !theme.isMobile && createGoalAllowed
                ? openCreateDialog
                : undefined
            }
          />
          {showPremiumBanner && (
            <>
              <Styled.PremiumBannerContainer>
                <PremiumBanner
                  title={t(
                    createGoalEnforced
                      ? 'pages.goals.premium-banner.title'
                      : 'pages.goals.premium-banner.not-enforced.title',
                    { total: createGoalTotal, limit: createGoalLimit },
                  )}
                  description={t(
                    createGoalEnforced
                      ? userHadTrial
                        ? 'pages.goals.premium-banner.description'
                        : 'pages.goals.premium-banner.try-for-free.description'
                      : 'pages.goals.premium-banner.not-enforced.description',
                    { limit: createGoalLimit },
                  )}
                  onClick={openPremiumDialog}
                  isTrial={!userHadTrial}
                />
              </Styled.PremiumBannerContainer>
              {!createGoalAllowed && (
                <GoalDataGrid
                  goals={frozenGoals}
                  allGoals={goals}
                  lifeAreas={lifeAreas}
                  favorites={favoriteIds}
                  onCreateGoal={!theme.isMobile ? openPremiumDialog : undefined}
                  onGoal={openPremiumDialog}
                  isFrozen
                  hideHeader
                  showPremiumLabel
                />
              )}
            </>
          )}
        </TabPanel>
      )}
    </GridContainer>
  );
};
