import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { AddGoalContextMenu } from 'shared/components/connected/add-goal-context-menu';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';

export const HeaderButton: React.FC = () => {
  const { t } = useTranslation();

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const openPremiumDialog = useOpenPremiumDialog();
  const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();

  const { position, open, close } = useContextMenuState();

  const onAdd = (e: React.MouseEvent) =>
    createGoalAllowed ? open(e) : openPremiumDialog();

  useClickOutside(contextMenuRef, close);

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={onAdd}
        size={Sizes.Medium}
        variant={Variants.Outlined}
        color={Colors.Inherit}
        startIcon={Plus}
      >
        {t('pages.goals.header.add-goal')}
      </Button>
      {!!position && (
        <AddGoalContextMenu
          ref={contextMenuRef}
          referenceElement={buttonRef}
          onClose={close}
        />
      )}
    </>
  );
};
