import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoalTemplateInstance,
  TaskTemplateInstance,
} from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import {
  getOtherSectionId,
  isOtherSection,
} from 'shared/utils/goal-section-id-helpers';
import { sortItems } from 'shared/utils/sort-items';

import { GoalList } from './goal-list';
import { GoalSectionList } from './goal-section-list';
import { TasksList } from './tasks-list';

export type GoalTemplatePreviewActionPlanProps = {
  mainGoal?: GoalTemplateInstance;
  sectionId?: ID;
  goals: GoalTemplateInstance[];
  tasks: TaskTemplateInstance[];

  noRightSpacing?: boolean;

  lifeAreas: LifeArea[];
};

export const GoalTemplatePreviewActionPlan: React.FC<
  GoalTemplatePreviewActionPlanProps
> = ({ mainGoal, sectionId, goals, tasks, noRightSpacing, lifeAreas }) => {
  const { t } = useTranslation();
  const subGoals = useMemo(
    () =>
      !sectionId && !!mainGoal?.id
        ? sortItems(
            goals.filter(
              ({ parentIds }) =>
                !!parentIds?.length && parentIds.includes(mainGoal.id),
            ),
            mainGoal?.goalSorting ?? [],
            'id',
          )
        : [],
    [goals, mainGoal?.goalSorting, mainGoal?.id, sectionId],
  );

  const section = useMemo(
    () =>
      sectionId
        ? mainGoal?.sections?.find((section) => section.id === sectionId)
        : undefined,
    [mainGoal, sectionId],
  );

  const ownTasks = useMemo(
    () =>
      sortItems(
        tasks.filter(({ id, goalId }) => {
          // filter out everything that is not within the goal
          if (goalId !== mainGoal?.id) {
            return false;
          }
          // if `other` section, take everything that is not in sections
          if (sectionId && isOtherSection(sectionId)) {
            return !mainGoal?.sections?.some(({ tasks }) => tasks.includes(id));
          }
          return section ? section.tasks.includes(id) : true;
        }),
        section?.tasks ?? mainGoal?.taskSorting ?? [],
        'id',
      ),
    [
      mainGoal?.id,
      mainGoal?.sections,
      mainGoal?.taskSorting,
      section,
      sectionId,
      tasks,
    ],
  );

  const ownSections = useMemo(
    () =>
      !sectionId && mainGoal?.sections?.length
        ? [
            ...mainGoal.sections,
            {
              id: getOtherSectionId(mainGoal.id),
              tasks: sortItems(
                ownTasks.filter(
                  ({ id }) =>
                    !mainGoal.sections?.some(({ tasks }) => tasks.includes(id)),
                ),
                mainGoal.taskSorting ?? [],
                'id',
              ).map(({ id }) => id),
              name: t('general.labels.goal.sections.other.label'),
              createdAt: new Date(),
            },
          ]
        : [],
    [
      mainGoal?.id,
      mainGoal?.sections,
      mainGoal?.taskSorting,
      ownTasks,
      sectionId,
      t,
    ],
  );

  return (
    <>
      {!!subGoals.length && (
        <GoalList
          goals={subGoals}
          allGoals={goals}
          tasks={tasks}
          lifeAreas={lifeAreas}
          noRightSpacing={noRightSpacing}
        />
      )}
      {!!mainGoal && !!ownSections.length && (
        <GoalSectionList
          sections={ownSections}
          goal={mainGoal}
          tasks={tasks}
          lifeAreas={lifeAreas}
          noRightSpacing={noRightSpacing}
        />
      )}
      {!ownSections.length && (
        <TasksList
          tasks={ownTasks}
          noRightSpacing={noRightSpacing}
          hasIndentation={!sectionId}
        />
      )}
    </>
  );
};
