import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';
import { getScheduleTimeframeCompletion } from 'shared/utils/get-schedule-timeframe-completion';
import { getUniqueDayEntries } from 'shared/utils/get-unique-day-entries';

import { isScheduleDue } from './is-schedule-due';
import { Options } from './types';

export const getFrequencyDueDateBySchedule = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (isScheduleDue(schedule, { today, weekStartsOn })) {
    return endOfDay(today);
  }

  // get the completions data for the schedule timeframe
  const {
    completions: completionsCurrentTimeframe,
    totalUniqueDays: totalCurrentTimeframe,
  } = getScheduleTimeframeCompletion(schedule, {
    referenceDate: today,
    weekStartsOn,
  });

  const uniqueDayCompletionsInTimeframe = getUniqueDayEntries(
    completionsCurrentTimeframe,
  );
  const nextCompletionDate =
    uniqueDayCompletionsInTimeframe.length < totalCurrentTimeframe
      ? endOfDay(addDays(today, 1))
      : endOfDay(
          schedule.frequency?.unit === FrequencyUnit.Week
            ? addWeeks(
                startOfWeek(today, {
                  weekStartsOn: weekdaysNumberMap[weekStartsOn],
                }),
                1,
              )
            : addMonths(startOfMonth(today), 1),
        );

  const lastSkip = schedule.skips?.[schedule.skips.length - 1];

  return [
    nextCompletionDate,
    lastSkip ? endOfDay(addDays(lastSkip, 1)) : undefined,
  ]
    .filter(Boolean)
    .sort(
      (nextDueDateA, nextDueDateB) =>
        nextDueDateB!.getTime() - nextDueDateA!.getTime(),
    )[0];
};
