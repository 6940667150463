import { addDays, endOfDay, isAfter, isSameDay, startOfDay } from 'date-fns';
import { RRule } from 'rrule';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { getScheduleTimeframeCompletion } from 'shared/utils/get-schedule-timeframe-completion';
import { getUniqueDayEntries } from 'shared/utils/get-unique-day-entries';

import { Options } from './types';

const frequencyDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  // if skipped till the future its not due
  const lastSkip = schedule.skips?.[schedule.skips.length - 1];
  if (lastSkip && (isAfter(lastSkip, today) || isSameDay(lastSkip, today))) {
    return false;
  }

  // no completions means its due
  if (!schedule.completions?.length) {
    return true;
  }

  // check if today is completed
  const completionsToday =
    schedule.completions?.filter((completion) =>
      isSameDay(completion, today),
    ) ?? [];
  const completedToday =
    completionsToday.length >= (schedule.targetFrequency?.count ?? 1);

  if (completedToday) {
    return false;
  }

  // get the current timeframe completions
  const {
    completions: completionsCurrentTimeframe,
    totalUniqueDays: totalAllowedUniqueDayCompletionsCurrentTimeframe,
  } = getScheduleTimeframeCompletion(schedule, {
    referenceDate: today,
    weekStartsOn,
  });

  const uniqueDayCompletionsInTimeframe = getUniqueDayEntries(
    completionsCurrentTimeframe,
  );

  // if completions within the current timeframe is less than the allowed count its due
  return (
    uniqueDayCompletionsInTimeframe.length <
    totalAllowedUniqueDayCompletionsCurrentTimeframe
  );
};

const rruleDue = (schedule: HabitSchedule, { today }: Options) => {
  // if skipped till the future its not due
  const lastSkip = schedule.skips?.[schedule.skips.length - 1];
  if (lastSkip && (isAfter(lastSkip, today) || isSameDay(lastSkip, today))) {
    return false;
  }

  // check if today is completed
  const completionsToday =
    schedule.completions?.filter((completion) =>
      isSameDay(completion, today),
    ) ?? [];
  const completedToday =
    completionsToday.length >= (schedule.targetFrequency?.count ?? 1);

  if (completedToday) {
    return false;
  }

  // check if day is an occurrence in the rrule
  const rrule = RRule.fromString(schedule.rrule.format);
  rrule.options.dtstart = endOfDay(addDays(today, -1));
  const [dueOnDay] = rrule.between(startOfDay(today), endOfDay(today), true);

  return !!dueOnDay;
};

export const isScheduleDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (
    !isSameDay(schedule.startDate, today) &&
    isAfter(schedule.startDate, today)
  ) {
    return false;
  }

  if (schedule.frequency?.count) {
    return frequencyDue(schedule, { today, weekStartsOn });
  }

  return rruleDue(schedule, { today, weekStartsOn });
};
