import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogVariantOptions } from 'shared/assets/styles';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './warning-dialog.style';

export type WarningDialogProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const WarningDialog: React.FC<WarningDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      variant={DialogVariantOptions.Warning}
      onClose={onClose}
    >
      <Styled.Container>
        <Styled.Header>
          <Typography variant="h5" component="span">
            {t('dialogs.premium-warning.discount.one-time.title')}
          </Typography>
        </Styled.Header>
        <Styled.Body>
          <Typography variant="body2" component="span">
            {t('dialogs.premium-warning.discount.one-time.description')}
          </Typography>
        </Styled.Body>
        <Styled.Footer>
          <Button
            variant={Variants.Outlined}
            size={Sizes.Medium}
            color={Colors.Primary}
            onClick={onSubmit}
          >
            {t('dialogs.premium-warning.discount.one-time.interactions.submit')}
          </Button>
          <Button
            variant={Variants.Outlined}
            size={Sizes.Medium}
            color={Colors.Inherit}
            onClick={onClose}
          >
            {t('dialogs.premium-warning.discount.one-time.interactions.cancel')}
          </Button>
        </Styled.Footer>
      </Styled.Container>
    </Dialog>
  );
};
