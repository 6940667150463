import { globalQueryParamOptions } from './globally-allowed-query-parameters';

export const getGlobalAllowedSearchParameters = (search?: URLSearchParams) => {
  const params = search ?? new URLSearchParams(window.location.search);

  return globalQueryParamOptions.reduce((acc, globalQueryParamOption) => {
    const values = params.getAll(globalQueryParamOption);
    if (values) {
      values.forEach((value) => acc.append(globalQueryParamOption, value));
    }
    return acc;
  }, new URLSearchParams());
};
