import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { RRule } from 'rrule';
import { weekdaysNumberMap } from 'shared/constants';
import {
  FrequencyUnit,
  HabitSchedule,
  TargetFrequencyUnit,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getDatesInTimeframe } from 'shared/utils/get-dates-in-timeframe';
import { getRruleFrequency } from 'shared/utils/get-rrule-dates';

export type Options = {
  referenceDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const getScheduleTimeframeCompletion = (
  schedule: HabitSchedule,
  { referenceDate, weekStartsOn }: Options,
) => {
  const rrule = RRule.fromString(schedule.rrule.format);
  const frequency =
    // if frequency is present, frequency unit is used
    schedule.frequency?.unit ??
    (rrule.options.freq === RRule.MONTHLY
      ? FrequencyUnit.Month
      : FrequencyUnit.Week);

  const timeframeStartDate =
    frequency === FrequencyUnit.Week
      ? startOfWeek(referenceDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        })
      : startOfMonth(referenceDate);
  // get the endDate to get the completions from, based on the frequency unit
  const timeframeEndDate =
    frequency === FrequencyUnit.Week
      ? endOfWeek(referenceDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        })
      : endOfMonth(referenceDate);
  // get completions of the current timeframe
  const completions = getDatesInTimeframe(
    schedule.completions ?? [],
    timeframeStartDate,
    timeframeEndDate,
  );

  const totalUniqueDays =
    schedule.frequency?.count ??
    getRruleFrequency({
      format: schedule.rrule.format,
      startDate: timeframeStartDate,
      endDate: timeframeEndDate,
    });

  return {
    completions,
    totalUniqueDays,
    total:
      schedule.targetFrequency?.unit === TargetFrequencyUnit.Day
        ? totalUniqueDays * (schedule.targetFrequency?.count ?? 1)
        : schedule.targetFrequency?.count ?? totalUniqueDays,
  };
};
