import {
  getLifeAreaDocById,
  updateLifeArea,
} from 'shared/services/backend-api';
import { Task } from 'shared/types/task';

export const updateLinkedLifeArea = async (oldTask: Task, task: Task) => {
  if (!oldTask.lifeAreaId) {
    return;
  }

  // get goal from task
  const lifeArea = await getLifeAreaDocById(oldTask.lifeAreaId);
  if (!lifeArea || !lifeArea.taskSorting?.length) {
    return;
  }

  // add new main task to taskSorting, if oldTask was in it
  const taskIndex = lifeArea.taskSorting.findIndex(
    (taskId) => taskId === oldTask.id,
  );
  if (taskIndex < 0) {
    return;
  }

  const taskSorting = lifeArea.taskSorting.toSpliced(taskIndex, 0, task.id);
  updateLifeArea({ id: lifeArea.id, taskSorting });
};
