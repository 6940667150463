import { CSS, Transform } from '@dnd-kit/utilities';
import { SortMode } from 'shared/hooks/use-data-grid-sorting';
import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

import { ColumnType } from './types';

const columnTypeToWidth = (type: ColumnType) => {
  switch (type) {
    case ColumnType.Deadline:
      return css`
        flex: 0 0 12rem;
        max-width: 12rem;
      `;
    case ColumnType.Completion:
      return css`
        flex: 0 0 11rem;
        max-width: 11rem;
      `;
    case ColumnType.Tasks:
      return css`
        flex: 0 0 8rem;
        max-width: 8rem;
      `;
    case ColumnType.LifeArea:
      return css`
        flex: 0 0 13.6rem;
        max-width: 13.6rem;
      `;
    case ColumnType.Empty:
      return css`
        flex: 0 0 5.2rem;
        max-width: 5.2rem;
      `;
    default:
      return css`
        min-width: 32.8rem;
      `;
  }
};

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.ul`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;
`;

export const HeaderItem = styled.li<{ $type: ColumnType }>`
  flex: 1 1 auto;

  overflow: hidden;

  ${({ $type }) => columnTypeToWidth($type)};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const OrderIcon = styled.span<{
  $sortMode: SortMode | undefined;
  $active: boolean;
}>`
  transform: rotate(270deg);
  transition:
    opacity 200ms,
    transform 200ms;

  line-height: 62.5%;
  font-size: 1.2rem;
  opacity: ${({ $active }) => ($active ? 1 : 0)};

  ${({ $sortMode }) =>
    $sortMode === SortMode.Desc &&
    css`
      transform: rotate(90deg);
    `};
`;

export const HeaderColumn = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};

  height: 3.2rem;
  width: 100%;
  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  border: none;
  background: transparent;
  cursor: pointer;
  transition: color 200ms;

  text-align: left;
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $active, theme }) =>
    $active &&
    css`
      color: ${theme.palette.text.primary};
    `}

  &:hover {
    ${OrderIcon} {
      opacity: 1;
    }
  }
`;

export const Body = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const BodyItem = styled.li`
  width: 100%;
`;

export const RowContainer = styled.div`
  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `};
`;

export const RowLink = styled(QueryNavLink)`
  text-decoration: none;
  color: inherit;
`;

const containerBase = css<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ theme, $selected }) =>
    $selected ? theme.palette.brown['50'] : theme.palette.background.default};
`;

export const MobileContainer = styled.div<{ $selected: boolean }>`
  ${containerBase};
  border-radius: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
  }
`;

export const DesktopContainer = styled.div<{ $selected: boolean }>`
  ${containerBase};
  border-radius: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const DragHandle = styled.div`
  position: absolute;
  right: calc(100% - ${({ theme }) => theme.spacing(2)});
  top: 50%;

  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 200ms;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DataRow = styled.ul<{
  $isArchived: boolean;
  $isDragging?: boolean;
  $transform?: Transform | null;
  $transition?: string;
  $selected?: boolean;
}>`
  position: relative;

  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;

  transform: ${({ $transform }) =>
    $transform && CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => `background 200ms, ${$transition}`};

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      > * {
        opacity: 0;
        pointer-events: none;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: ${({ theme }) => theme.spacing(2)};
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
    `};

  // adjusting items that are not dragged
  ${({ $transform }) =>
    !$transform &&
    css`
      @media (any-hover: hover) {
        &:hover {
          ${DragHandle} {
            opacity: 1;
          }
        }
      }
    `};

  ${({ $isArchived }) =>
    $isArchived &&
    css`
      opacity: 0.5;
    `}
`;

export const DataRowItem = styled.li<{ $type: ColumnType }>`
  display: flex;
  align-items: center;

  flex: 1 1 auto;
  ${({ $type }) => columnTypeToWidth($type)};
  height: 4.8rem;

  overflow: hidden;
`;

export const DataCell = styled.div<{
  $empty?: boolean;
  $isFrozen: boolean;
  $hasButton?: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  position: relative;

  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $hasButton, theme }) =>
    $hasButton &&
    css`
      color: ${theme.palette.grey['500']};
    `};

  ${({ $empty, $isFrozen, theme }) =>
    ($empty || $isFrozen) &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const ProgressContainer = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;
