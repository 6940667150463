import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';
import { PageEvents } from 'shared/services/tracking';

import { EmptyCategoryList } from './components/empty-category-list';
import { Header } from './components/header';
import { LoadingOverview, Overview } from './components/overview';
import {
  LoadingSearchOverview,
  SearchOverview,
} from './components/search-overview';
import * as Styled from './goals-templates-overview.style';

export const GoalTemplatesOverview: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.GoalTemplatesOverview, undefined);
  const [searchText, setSearchText] = useQueryParam(QueryParamOptions.Search);

  return (
    <Styled.Container>
      <GridContainer>
        <Styled.Content>
          <Header initialSearch={searchText ?? ''} onSearch={setSearchText} />
          {searchText ? (
            <Suspense fallback={<LoadingSearchOverview />}>
              <SearchOverview searchText={searchText} />
            </Suspense>
          ) : (
            <ErrorBoundary fallback={<EmptyCategoryList />}>
              <Suspense fallback={<LoadingOverview />}>
                <Overview />
              </Suspense>
            </ErrorBoundary>
          )}
        </Styled.Content>
      </GridContainer>
    </Styled.Container>
  );
};
