import { useQuery } from '@tanstack/react-query';
import { createSubscriptionDoc } from 'shared/lib/@tanstack-query';
import { getGoalById } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'goal-by-id';

export const useGoalByIdQuery = (uid: ID = '', id: ID = '') =>
  useQuery({
    queryKey: [queryKey, uid, id],
    retry: false,
    queryFn: createSubscriptionDoc(() => getGoalById(id)),
    enabled: !!uid && !!id,
  });
