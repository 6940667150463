import { Components, Theme } from '@mui/material';

import { index, Options } from '../../z-index';

export enum DialogVariantOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Premium = 'premium',
  Warning = 'warning',
}

const mobilePaper = (theme: Omit<Theme, 'components'>) => ({
  margin: `${theme.spacing(12)} 0 0`,
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  maxHeight: `calc(100% - ${theme.spacing(12)})`,

  borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} 0 0`,
  boxShadow: theme.shadowBox.dialog,
  background: theme.palette.background.default,
});

const desktopPaper = (theme: Omit<Theme, 'components'>) => ({
  margin: theme.spacing(4),
  width: `calc(100% - (${theme.spacing(4)} * 2))`,
  maxWidth: '46.4rem',
  maxHeight: `calc(100% - ${theme.spacing(16)})`,

  borderRadius: theme.spacing(2),
  boxShadow: theme.shadowBox.dialog,
  background: theme.palette.background.default,
});

export const MuiDialog: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
  styleOverrides: {
    root: {
      zIndex: index(Options.DIALOG),
    },
    paper: ({ theme }) =>
      theme.isMobile ? mobilePaper(theme) : desktopPaper(theme),
  },
  variants: [
    {
      props: { variant: DialogVariantOptions.Warning },
      style: ({ theme }) => ({
        '& .MuiDialog-paper': {
          ...desktopPaper(theme),
          width: 'unset',
          height: 'unset',
        },
      }),
    },
    {
      props: { variant: DialogVariantOptions.Premium },
      style: ({ theme }) => ({
        '& .MuiDialog-paper': {
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          margin: 0,
          padding: 0,
          borderRadius: 0,

          [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            ...desktopPaper(theme),
            maxWidth: '64rem',
            height: 'unset',
          },
        },
      }),
    },
    {
      props: { variant: DialogVariantOptions.Medium },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: '64rem',
        },
      },
    },
    {
      props: { variant: DialogVariantOptions.Large },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: '90rem',
        },
      },
    },
  ],
};

declare module '@mui/material/Dialog' {
  interface DialogProps {
    variant?: DialogVariantOptions;
  }
}
