import { useQuery } from '@tanstack/react-query';
import { getSubscriptionPrices } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'subscription-prices';

export const useSubscriptionPricesQuery = (uid?: ID) =>
  useQuery({
    queryKey: [queryKey, uid],
    queryFn: async () => getSubscriptionPrices(),
    enabled: !!uid,
  });
