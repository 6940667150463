import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useUpdateGoalOrder,
} from 'features/user';
import React, { useMemo } from 'react';
import { SelfSortingGoalDataGrid } from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useToday } from 'shared/contexts/today';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

import { EmptyCompleted } from './components/empty-completed';
import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Completed: React.FC = () => {
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(GoalRoadmapSortingKeys.Completed);
  const favoriteIds = useFavoriteIds();

  const updateOrder = useUpdateGoalOrder(GoalRoadmapSortingKeys.Completed);

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.Completed](goals, today).sort(
          (goalA, goalB) =>
            goalB.completedAt!.getTime() - goalA.completedAt!.getTime(),
        ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  return (
    <GridContainer hasFlex1={!filteredGoals.length}>
      {!filteredGoals.length ? (
        <EmptyCompleted />
      ) : (
        <TabPanel role="tab-panel">
          <SelfSortingGoalDataGrid
            goals={filteredGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onReorder={updateOrder}
          />
        </TabPanel>
      )}
    </GridContainer>
  );
};
