import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { HabitForm } from 'shared/components/ui/habit-form';
import { Goal } from 'shared/types/goal';
import { HabitFormFields } from 'shared/types/habit-form';
import { LifeArea } from 'shared/types/life-area';
import { WeekDays } from 'shared/types/week-days';

import { HabitFormError } from './habit-form-error';
import { HabitFormLoading } from './habit-form-loading';

export type HabitFormDialogProps = {
  initialValues?: Partial<HabitFormFields>;
  goals: Goal[];
  lifeAreas: LifeArea[];
  weekStartsOn: WeekDays;
  open?: boolean;
  onClose: () => void;
  onSubmit: (habit: HabitFormFields) => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
  isEdit?: boolean;
  isCustomIconAllowed?: boolean;
  isCompleted?: boolean;
  isArchived?: boolean;
  isHabitCreationAllowed: boolean;
  onPremium: () => void;
  onComplete?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
};

export const HabitFormDialog: React.FC<HabitFormDialogProps> = ({
  initialValues,
  goals,
  lifeAreas,
  weekStartsOn,
  open,
  onClose,
  onSubmit,
  onRetry,
  isLoading,
  isError,
  isEdit,
  isCustomIconAllowed,
  isCompleted,
  isArchived,
  isHabitCreationAllowed,
  onPremium,
  onComplete,
  onArchive,
  onDelete,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return (
        <HabitFormError onRetry={onRetry} onCancel={onClose} isEdit={isEdit} />
      );
    }
    if (isLoading) {
      return <HabitFormLoading />;
    }

    return (
      <HabitForm
        initialValues={initialValues}
        goals={goals}
        lifeAreas={lifeAreas}
        weekStartsOn={weekStartsOn}
        onSubmit={onSubmit}
        onCancel={onClose}
        isEdit={isEdit}
        isActive={!!open}
        isCustomIconAllowed={isCustomIconAllowed}
        isCompleted={isCompleted}
        isArchived={isArchived}
        isHabitCreationAllowed={isHabitCreationAllowed}
        onPremium={onPremium}
        onComplete={onComplete}
        onArchive={onArchive}
        onDelete={onDelete}
      />
    );
  }, [
    isError,
    isLoading,
    initialValues,
    goals,
    lifeAreas,
    weekStartsOn,
    onSubmit,
    onClose,
    isEdit,
    open,
    isCustomIconAllowed,
    isCompleted,
    isArchived,
    isHabitCreationAllowed,
    onPremium,
    onComplete,
    onArchive,
    onDelete,
    onRetry,
  ]);

  return (
    <Dialog open={!!open} fullWidth>
      {content}
    </Dialog>
  );
};
