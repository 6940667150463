import {
  deleteApp,
  FirebaseApp,
  initializeApp,
  // setLogLevel,
} from 'firebase/app';

let app: FirebaseApp | undefined;

export const initApp = () => {
  if (!app) {
    app = initializeApp({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    });

    // setLogLevel('debug');
  }

  return app;
};

export const clearApp = async () => {
  if (app) {
    await deleteApp(app);
  }
  app = undefined;
};
