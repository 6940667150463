import { Goal } from 'shared/types/goal';
import { DefaultMetricOptions } from 'shared/types/metric-template';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';

export type Options = {
  goals: Goal[];
  actualCount?: boolean;
};

export type GoalCompletion = {
  start: number;
  current: number;
  end: number;
};

export const getGoalCompletion = (
  goal: Goal,
  { goals, actualCount }: Options,
): GoalCompletion => {
  if (goal.completedAt && !actualCount) {
    return {
      start: 0,
      current: 1,
      end: 1,
    };
  }

  if (!goal.metric?.id) {
    return {
      start: 0,
      current: 0,
      end: Infinity,
    };
  }

  const subGoals = goals.filter(
    ({ parentIds, archivedAt }) =>
      !archivedAt && parentIds?.[parentIds.length - 1] === goal.id,
  );

  if (goal.metric?.id === DefaultMetricOptions.CompletedTasks) {
    return {
      start: 0,
      current: subGoals.reduce(
        (acc, { completedTaskCount }) => acc + (completedTaskCount ?? 0),
        goal.completedTaskCount ?? 0,
      ),
      end: subGoals.reduce(
        (acc, { taskCount }) => acc + (taskCount ?? 0),
        goal.taskCount ?? 0,
      ),
    };
  }

  if (goal.metric?.id === DefaultMetricOptions.SubGoalProgress) {
    // add 0-100 score per subgoal, whether it is task or metric based
    const current = subGoals.reduce<number>((acc, subGoal) => {
      if (!!subGoal.completedAt) {
        return acc + 100;
      }

      if (!subGoal.metric) {
        return acc;
      }

      if (subGoal.metric.id === DefaultMetricOptions.CompletedTasks) {
        const completed = subGoal.completedTaskCount ?? 0;
        const total = subGoal.taskCount ?? 0;

        return acc + Math.min(100, Math.max(0, (completed / total) * 100 || 0));
      }

      const start = subGoal.metric?.startValue ?? 0;
      const end = subGoal.metric?.targetValue ?? Infinity;
      const lastEntry = subGoal.metric.entries?.toSorted(
        (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
      )[0];

      const completionPercentage = getCompletionPercentage({
        start,
        current: lastEntry?.value ?? start,
        end,
      });

      return acc + (end === Infinity ? 0 : completionPercentage || 0);
    }, 0);

    // all sub-goals count for the same portion
    const end = subGoals.length * 100;

    return {
      start: 0,
      current,
      end,
    };
  }

  const start = goal.metric?.startValue ?? 0;

  return {
    start,
    end: goal.metric?.targetValue ?? Infinity,
    current:
      goal.metric?.entries?.toSorted(
        ({ date: dateA }, { date: dateB }) => dateB.getTime() - dateA.getTime(),
      )[0]?.value ?? start,
  };
};
