import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './purchase-premium.style';

const uspTranslations = [
  'dialogs.premium.usp.1',
  'dialogs.premium.usp.2',
  'dialogs.premium.usp.3',
  'dialogs.premium.usp.4',
  'dialogs.premium.usp.5',
  'dialogs.premium.usp.6',
];

export const UspList = () => {
  const { t } = useTranslation();

  return (
    <Styled.UspList>
      {uspTranslations.map((translationKey) => (
        <Styled.UspListItem key={translationKey}>
          <Styled.UspListItemIcon>
            <Icon icon={Check} />
          </Styled.UspListItemIcon>
          <Typography variant="subtitle2">{t(translationKey)}</Typography>
        </Styled.UspListItem>
      ))}
    </Styled.UspList>
  );
};
