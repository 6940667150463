export const isTouchDevice = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  matchMedia('(pointer: coarse)').matches;

declare module '@mui/material/styles' {
  interface Theme {
    isMobile: boolean;
    isTouchDevice: boolean;
  }

  interface ThemeOptions {
    isMobile?: boolean;
    isTouchDevice?: boolean;
  }
}
