import { isAfter } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Check,
  ChevronLeft,
  Flag,
  IconFormIcons,
  IconFormNames,
  Image as ImageIcon,
  Lock,
  MoreHorizontal,
} from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Timeframe } from 'shared/components/ui/timeframe';
import { useToday } from 'shared/contexts/today';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { GoalMetric } from 'shared/types/goal-metric';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { countMetricOptions } from 'shared/types/metric-template';
import { Timestamp } from 'shared/types/timestamp';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import * as Styled from './action-plan-goal-header.style';

export type ActionPlanGoalHeaderProps = {
  id: ID;
  name: string;
  image?: Image | null;
  iconName?: IconFormNames | null;
  color?: string;
  progress?: number;
  startDate: Timestamp | null;
  deadline: Timestamp | null;
  metric?: GoalMetric | null;
  isMainGoal?: boolean;
  isCompleted?: boolean;
  isArchived?: boolean;
  isFrozen?: boolean;

  imagePlaceholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onOpen?: () => void;
  isOpen?: boolean;
  onUpdateGoalProgress?: (id: ID) => void;
  onPremium: () => void;
  isDragging?: boolean;
  isMoving?: boolean;
  isHovered?: boolean;
  isPreview?: boolean;
};

export const ActionPlanGoalHeader: React.FC<ActionPlanGoalHeaderProps> = ({
  id,
  image,
  iconName,
  imagePlaceholderIcon = ImageIcon,
  color,
  name,
  progress,
  startDate,
  deadline,
  metric,
  onOpen,
  isOpen,
  isMainGoal,
  onUpdateGoalProgress,
  onPremium,
  isArchived,
  isCompleted,
  isDragging,
  isMoving,
  isHovered,
  isFrozen,
  isPreview,
}) => {
  const today = useToday();
  const theme = useTheme();
  const navigate = useNavigate();

  const [menuPosition, setMenuPosition] = useState<
    { left: number; top: number } | undefined
  >(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onUnfold = () => {
    if (isFrozen) {
      onPremium();
      return;
    }
    onOpen?.();
  };

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isPreview) {
      return;
    }

    setMenuPosition({ left: e.clientX, top: e.clientY });
    openMenu();
  };

  const onMoreButton = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuPosition(undefined);
    openMenu();
  };

  const onNavigate = theme.isMobile
    ? () => navigate(replaceUrlParams(Paths.GoalDetail, { id }))
    : undefined;

  const onCloseMenu = () => {
    setMenuPosition(undefined);
    closeMenu();
  };

  const onUpdateProgress = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdateGoalProgress?.(id);
    onCloseMenu();
  };

  const updateProgressDisabled =
    !metric?.id ||
    countMetricOptions.includes(metric?.id) ||
    isFrozen ||
    isArchived ||
    isPreview;

  return (
    <>
      <Styled.Container
        onContextMenu={onContextMenu}
        $isDragging={!!isDragging}
        $isMoving={!!isMoving}
        $isArchived={!!isArchived}
      >
        <Styled.UnfoldButton onClick={onUnfold}>
          <Styled.UnfoldIconContainer $open={!!isOpen}>
            <Icon icon={ChevronLeft} />
          </Styled.UnfoldIconContainer>
        </Styled.UnfoldButton>

        <Styled.Body
          $isDragging={!!isDragging}
          $isHovered={!!isHovered}
          $isOpen={!!isOpen}
        >
          <Styled.IllustrationContainer>
            {image ? (
              <Styled.BlurImageContainer $isCompleted={!!isCompleted}>
                <BlurHashImage hash={image.blurHash} src={image.url} />
              </Styled.BlurImageContainer>
            ) : (
              <Styled.ImageIconContainer $isCompleted={!!isCompleted}>
                <Icon
                  icon={
                    iconName ? IconFormIcons[iconName] : imagePlaceholderIcon
                  }
                />
              </Styled.ImageIconContainer>
            )}
            {isCompleted && (
              <Styled.CompletedIconContainer>
                <Icon icon={Check} />
              </Styled.CompletedIconContainer>
            )}
            {!!color && <Styled.ColorDot $color={color} />}
          </Styled.IllustrationContainer>

          <Styled.Content>
            <Styled.LinkContainer $isCompleted={!!isCompleted}>
              <Styled.Link
                to={replaceUrlParams(Paths.GoalDetail, { id })}
                onClick={onNavigate}
                as={
                  theme.isMobile || isFrozen || isPreview ? 'span' : undefined
                }
                $isFrozen={!!isFrozen}
                $isMain={!!isMainGoal}
              >
                <Styled.LinkLabel>{name}</Styled.LinkLabel>
                <Styled.LinkIconContainer $isFaded={!!isFrozen}>
                  <Icon icon={isFrozen ? Lock : ChevronLeft} />
                </Styled.LinkIconContainer>
              </Styled.Link>
            </Styled.LinkContainer>

            <Styled.MetaContainer>
              <Styled.MetaEntry $isFrozen={!!isFrozen} $type="progress">
                <Styled.ProgressButton
                  onClick={
                    updateProgressDisabled ? undefined : onUpdateProgress
                  }
                  as={updateProgressDisabled ? 'span' : undefined}
                >
                  <CircleProgress
                    percentage={progress ?? 0}
                    isPrimary={!isFrozen && !theme.isMobile}
                    isSmall={theme.isMobile}
                  />
                </Styled.ProgressButton>
              </Styled.MetaEntry>
              <Styled.MetaEntry $isFrozen={!!isFrozen} $type="deadline">
                {!isCompleted && !!startDate && isAfter(startDate, today) ? (
                  <Timeframe
                    startDate={startDate}
                    endDate={deadline}
                    hasLargeIcon
                  />
                ) : (
                  !isCompleted &&
                  !!deadline && <TimeLeft startIcon={Flag} date={deadline} />
                )}
              </Styled.MetaEntry>
            </Styled.MetaContainer>
          </Styled.Content>

          <Styled.MobileIconButtonContainer>
            <IconButton
              icon={ChevronLeft}
              rotation={180}
              size={Sizes.Large}
              as="span"
            />
          </Styled.MobileIconButtonContainer>

          {!isPreview && (
            <Styled.DesktopIconButtonContainer>
              <IconButton
                ref={buttonRef}
                icon={MoreHorizontal}
                onClick={onMoreButton}
                size={Sizes.Medium}
              />
            </Styled.DesktopIconButtonContainer>
          )}
        </Styled.Body>
      </Styled.Container>

      {menuOpen && !theme.isMobile && (
        <GoalContextMenu
          id={id}
          referenceElement={menuPosition ? undefined : buttonRef}
          location={menuPosition}
          onClose={closeMenu}
        />
      )}
    </>
  );
};
