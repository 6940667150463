import { useCreateHabitAllowed } from 'features/tasks';
import React from 'react';
import { TaskMetaInteractionDueDate } from 'shared/components/ui/task-meta-interaction-due-date';
import { TaskMetaInteractionPriority } from 'shared/components/ui/task-meta-interaction-priority';
import { TaskMetaInteractionReminderTime } from 'shared/components/ui/task-meta-interaction-reminder-time';
import { TaskMetaInteractionRepeat } from 'shared/components/ui/task-meta-interaction-repeat';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { CreateTask } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { initialWeekStartsOn } from 'shared/types/week-days';

export type MetaInteractionsProps = {
  newTask: CreateTask;
  onChange: (value: Partial<CreateTask>) => void;
};

export const MetaInteractions: React.FC<MetaInteractionsProps> = ({
  newTask,
  onChange,
}) => {
  const user = useUser();
  const {
    allowed: createHabitAllowed,
    enforced: createHabitEnforced,
    limit: createHabitLimit,
    total: createHabitTotal,
  } = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const onChangeDeadline = (date?: Timestamp) =>
    onChange({
      endStrategy: {
        ...(newTask.endStrategy ?? {}),
        deadline: date ?? null,
      },
    });

  const onChangeRepeat = (changes: {
    schedules: HabitSchedule[] | null;
    type: TaskType;
  }) => onChange(changes);

  const onChangeReminder = (reminderTime?: number) =>
    onChange({ reminderTime });

  const onChangePriority = (priority: TaskPriorityOptions | null) =>
    onChange({ priority });

  const isHabitCreationAllowed =
    createHabitAllowed ||
    newTask.type === TaskType.Habit ||
    !createHabitEnforced ||
    createHabitLimit < createHabitTotal;

  return (
    <>
      <TaskMetaInteractionDueDate
        dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
        weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
        onChange={onChangeDeadline}
        value={newTask.endStrategy?.deadline ?? undefined}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionRepeat
        value={newTask.schedules}
        type={newTask.type ?? TaskType.Task}
        onChange={onChangeRepeat}
        weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
        changeToHabitAllowed={isHabitCreationAllowed}
        onPremium={openPremiumDialog}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionReminderTime
        onChange={onChangeReminder}
        value={newTask.reminderTime}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionPriority
        value={newTask.priority}
        onChange={onChangePriority}
        highlight
        isMobileOutlined
      />
    </>
  );
};
