import { QueryFunction } from '@tanstack/react-query';
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDocFromCache,
  onSnapshot,
} from 'firebase/firestore';
import { getRollbar } from 'shared/services/error-tracking';
import { ID } from 'shared/types/id';

import { queryClient } from './query-client';
import { addSubscription } from './subscriptions';

export type Options = {
  setTanstackError?: boolean;
};

export const createSubscriptionDoc = <T extends { id: ID }>(
  doc: () => Promise<DocumentReference<T>> | DocumentReference<T>,
  { setTanstackError = true }: Options = {},
): QueryFunction<T | undefined, string[]> => {
  let firstRun = true;
  let latestSnapshotData: T;

  return (context) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc();

      const onSuccess = (response: DocumentSnapshot<T>) => {
        latestSnapshotData = (response.data() as T) ?? null;

        if (firstRun) {
          resolve(latestSnapshotData);
          firstRun = false;
          return;
        }

        queryClient.setQueryData(context.queryKey, latestSnapshotData);
      };

      const onError = (error: FirestoreError) => {
        getRollbar().error('Firestore Doc: Doc request failed', error, {
          custom: { queryKey: context.queryKey, error },
        });

        if (firstRun) {
          reject(error);
          firstRun = false;
          return;
        }

        // todo: when redirecting straight to detail page after creation, this will fail.
        //  Reason being is that the document isn't created yet on the BE. Has to be fixed before turning back on
        // if (setTanstackError) {
        //   // get the query we are fetching for
        //   const queryToSetErrorOn = queryClient
        //     .getQueryCache()
        //     .getAll()
        //     .find((query) => query.queryKey === context.queryKey);
        //
        //   // at least invalidate
        //   if (queryToSetErrorOn) {
        //     queryToSetErrorOn.setState({
        //       status: 'error',
        //       error,
        //     });
        //     return;
        //   }
        // }

        queryClient.invalidateQueries({
          queryKey: context.queryKey,
          exact: true,
        });
      };

      getDocFromCache(docRef)
        // eslint-disable-next-line promise/always-return -- temp
        .then(onSuccess)
        .catch(() => {
          // this is just cache, do nothing with this error
        });

      addSubscription(onSnapshot(docRef, onSuccess, onError));
    });
  };
};
