import React, { useEffect, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { getRollbar } from 'shared/services/error-tracking';

import { version } from '../../../package.json';
import { GenericError } from './generic-error';
import { NotFound } from './not-found';

const notFoundCodes = ['permission-denied'];

export const ErrorPage: React.FC = () => {
  const error = useRouteError() as any;
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    getRollbar().error(
      `error caught in route `,
      error as Error,
      { url: window.location.href },
      (_, data) =>
        setErrors((oldIds) =>
          [
            ...oldIds,
            data?.result?.uuid
              ? [version, data?.result?.uuid].join('-')
              : (undefined as unknown as string),
          ].filter(Boolean),
        ),
    );
  }, [error]);

  if (!error || notFoundCodes.includes(error.code)) {
    return <NotFound errors={errors} />;
  }

  return <GenericError errors={errors} />;
};
