import { CircularProgress } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: auto;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => `${theme.spacing(6)} 0 0`};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex-direction: row;
    padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(10)}`};
  }
`;

export const UspList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  list-style: none;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex: 1;
    padding: 0;
  }
`;

export const UspListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const UspListItemIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 1.8rem;
  width: 1.8rem;
  min-height: 1.8rem;
  height: 1.8rem;

  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const PaymentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(4)};

  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing(-1)} ${theme.spacing(4)} ${theme.spacing(-1.5)} ${rgba(theme.palette.common.black, 0.08)}`};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex: 1;
    position: initial;
    bottom: initial;
    left: initial;
    right: initial;

    border-top: none;
    box-shadow: none;
  }
`;

export const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;
`;

export const Loader = styled(CircularProgress)``;
