import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { getRollbar } from 'shared/services/error-tracking';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';
import { getUrlUtmParameters } from 'shared/utils/get-url-utm-parameters';

import { createEntryGoal } from './create-entry-goal';
import { getUser } from './get-user';
import { getAuthentication } from './helpers';
import { setUserUtmParameters } from './set-user-utm-parameters';

export const completeSignupAndLogin = async (
  code: string,
  password: string,
) => {
  const auth = await getAuthentication();

  try {
    const email = await verifyPasswordResetCode(auth, code);
    await confirmPasswordReset(auth, code, password);
    await signInWithEmailAndPassword(auth, email, password);
    const user = await getUser();

    if (!user) {
      return null;
    }

    try {
      const parameters = getUrlUtmParameters();
      await setUserUtmParameters(parameters);
    } catch (e) {
      getRollbar().error('Failed setting user utm parameters', e as Error);
    }

    try {
      await createEntryGoal();
    } catch (e) {
      getRollbar().error('Failed creating entry goal', e as Error);
    }

    return user;
  } catch (e) {
    throw ResetPasswordErrorTypes.Unknown;
  }
};
