import Typography from '@mui/material/Typography';
import { useEmailByOobCode, useResetPassword } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginHeader } from 'shared/components/ui/login-header';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';

import { ResetPasswordForm } from './components/reset-password-form';
import * as Styled from './reset-password.style';

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const [code] = useQueryParam(QueryParamOptions.OobCode);
  const email = useEmailByOobCode(code);
  const { resetPassword, isLoading, error } = useResetPassword();

  const submitForm = ({ password }: { password: string }) =>
    !!code && resetPassword({ password, code });

  return (
    <>
      <LoginHeader />
      <Styled.Container>
        <Styled.Body>
          <Typography variant="h1" textAlign="center">
            {t('forms.reset-password.title')}
          </Typography>

          <Styled.BodyContent>
            <Typography variant="body2">
              {t('forms.reset-password.description')}
            </Typography>
            <Typography variant="body2">
              {t('forms.reset-password.warning')}
            </Typography>

            <ResetPasswordForm
              email={email}
              onSubmit={submitForm}
              isLoading={isLoading}
              error={error ?? undefined}
            />
          </Styled.BodyContent>

          <Styled.Anchor to={Paths.Login}>
            {t('forms.reset-password.back-to-login.label')}
          </Styled.Anchor>
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
