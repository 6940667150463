import { IconFormNames } from 'shared/assets/icons';
import { ID } from 'shared/types/id';
import * as yup from 'yup';

export type GoalTemplate = {
  name: string | null;
  description: string;
  iconName: IconFormNames | null;
  inheritGoalProperties?: boolean;
  goalId: ID;
  isPublic: boolean;
};

export const validationSchema: yup.ObjectSchema<GoalTemplate> = yup.object({
  name: yup
    .string()
    .nullable()
    .when('inheritGoalProperties', {
      is: true,
      then: () => yup.string().nullable().oneOf([null]),
      otherwise: () => yup.string().nullable().required(),
    })
    .required(),
  description: yup.string().required(),
  iconName: yup
    .string<IconFormNames>()
    .nullable()
    .when('inheritGoalProperties', {
      is: true,
      then: () => yup.string().nullable().oneOf([null]),
      otherwise: () =>
        yup
          .string<IconFormNames>()
          .oneOf(Object.values(IconFormNames))
          .nullable()
          .required(),
    })
    .required(),
  inheritGoalProperties: yup.boolean().required(),
  goalId: yup.string().required(),
  isPublic: yup.boolean().required(),
});

export type GoalTemplateFormFields = yup.InferType<typeof validationSchema>;
