import { getGoalDocById, updateGoal } from 'shared/services/backend-api';
import { GoalSection } from 'shared/types/goal-section';
import { Task } from 'shared/types/task';

export const updateLinkedGoal = async (oldTask: Task, task: Task) => {
  if (!oldTask.goalId) {
    return;
  }

  // get goal from task
  const goal = await getGoalDocById(oldTask.goalId);
  if (!goal || (!goal.taskSorting?.length && !goal.sections?.length)) {
    return;
  }

  const goalUpdateBase = {
    id: goal.id,
    taskCount: 1,
    completedTaskCount: oldTask.completedAt ? 1 : 0,
  };

  // add new main task to taskSorting, if oldTask was in it
  const taskIndex = goal.taskSorting?.findIndex(
    (taskId) => taskId === oldTask.id,
  );

  if (taskIndex !== undefined && taskIndex >= 0) {
    const taskSorting = goal.taskSorting!.toSpliced(taskIndex, 0, task.id);

    updateGoal({ ...goalUpdateBase, taskSorting });
    return;
  }

  if (goal.sections?.length) {
    // add new main task to section, if old task was in it
    const sections = goal.sections?.reduce<GoalSection[]>((acc, section) => {
      const newSection = { ...section };
      if (section.tasks.includes(oldTask.id)) {
        const taskIndex = section.tasks.findIndex(
          (taskId) => taskId === oldTask.id,
        );
        newSection.tasks = section.tasks.toSpliced(taskIndex, 0, task.id);
      }

      acc.push(newSection);

      return acc;
    }, []);

    updateGoal({ ...goalUpdateBase, sections });
  }
};
