import { endOfDay, isAfter, isSameDay, subDays } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import { Checkbox } from 'shared/components/ui/checkbox';
import { useToday } from 'shared/contexts/today';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { WeekDays } from 'shared/types/week-days';
import { getRruleFrequency } from 'shared/utils/get-rrule-dates';
import { getScheduleTimeframeCompletion } from 'shared/utils/get-schedule-timeframe-completion';

import {
  dateCheckboxWidth,
  initialVisibleDates,
  weekLength,
} from '../../constants';
import { useScrollSmoothToPosition } from '../../hooks/use-scroll-smooth-to-position';
import { Week } from '../../types/week';
import * as Styled from './schedule.style';

export type ScheduleProps = {
  value: HabitSchedule;
  onCompleteDate: (date: Date) => void;
  weeks: Week[];
  visibleDates?: number;
  dateWidth?: number;
  scrollToDate?: number;
  weekStartsOn: WeekDays;
};

export const Schedule: React.FC<ScheduleProps> = ({
  value,
  onCompleteDate,
  weeks,
  dateWidth = dateCheckboxWidth,
  visibleDates = initialVisibleDates,
  scrollToDate,
  weekStartsOn,
}) => {
  const today = useToday();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const rruleMatchingDays = useMemo(
    () =>
      weeks.map((week) => ({
        ...week,
        validOptionDates: week.dates.filter(({ date }) =>
          getRruleFrequency({
            format: value.rrule.format,
            startDate: endOfDay(subDays(date, 1)),
            endDate: endOfDay(date),
          }),
        ),
      })),
    [value.rrule.format, weeks],
  );

  useScrollSmoothToPosition({
    ref: scrollContainerRef,
    scrollPosition: scrollToDate,
    itemWidth: dateWidth,
    visibleAmount: visibleDates,
    totalAmount: weeks.length * weekLength - 1,
  });

  return (
    <Styled.Container
      ref={scrollContainerRef}
      $visibleDates={visibleDates}
      $dateWidth={dateWidth}
    >
      {weeks.map(({ weekNumber, dates }, weekIndex) => {
        const { completions: completionsInWeek, total: frequency } =
          getScheduleTimeframeCompletion(value, {
            referenceDate: dates[0].date,
            weekStartsOn,
          });

        const percentage = completionsInWeek.length
          ? Math.round((completionsInWeek.length / frequency) * 100)
          : 0;

        return (
          <Styled.Week key={weekNumber}>
            {/* divider entry for start of the week, except the first week */}
            {!!weekIndex && (
              <Styled.WeekEntry $width={dateWidth}>
                <Styled.Divider />
              </Styled.WeekEntry>
            )}

            {dates.map(({ date }) => {
              const onChange = () => onCompleteDate(date);
              const isValidRruleOption =
                rruleMatchingDays[weekIndex].validOptionDates.length !==
                  Object.keys(WeekDays).length &&
                !!rruleMatchingDays[weekIndex].validOptionDates.find(
                  ({ date: validDate }) => isSameDay(date, validDate),
                );

              return (
                <Styled.WeekEntry $width={dateWidth} key={date.getTime()}>
                  <Checkbox
                    value={date.getTime()}
                    checked={value.completions.some((completedDate) =>
                      isSameDay(completedDate, date),
                    )}
                    disabled={!isSameDay(date, today) && isAfter(date, today)}
                    onChange={onChange}
                    isRecommended={isValidRruleOption}
                  />
                </Styled.WeekEntry>
              );
            })}

            {/* percentage entry for at the end of the week */}
            <Styled.WeekEntry $width={dateWidth} $completed={percentage >= 100}>
              {percentage}%
            </Styled.WeekEntry>
          </Styled.Week>
        );
      })}
    </Styled.Container>
  );
};
