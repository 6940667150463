import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, Star, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { useActiveTheme } from 'shared/lib/mui';
import { ThemeModes } from 'shared/types/theme-modes';

import * as Styled from './header.style';

export type LoadingHeaderProps = {
  onClose: () => void;
};

export const LoadingHeader: React.FC<LoadingHeaderProps> = ({ onClose }) => {
  const [themeMode] = useActiveTheme();

  return (
    <Styled.Container>
      {themeMode === ThemeModes.Dark ? (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-dark.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-dark@2x.png, /images/premium/purchase/premium-dialog-header-graphic-dark@3x.png"
        />
      ) : (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-light.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-light@2x.png, /images/premium/purchase/premium-dialog-header-graphic-light@3x.png"
        />
      )}

      <Styled.CloseButtonContainer>
        <IconButton
          icon={X}
          onClick={onClose}
          variant={Variants.Contained}
          size={Sizes.Large}
        />
      </Styled.CloseButtonContainer>

      <Styled.Body>
        <Styled.LogoContainer>
          <Icon icon={Logo} />

          <Styled.PremiumBatch>
            <Icon icon={Star} />
          </Styled.PremiumBatch>
        </Styled.LogoContainer>

        <Styled.Content>
          <Typography variant="h3" component="h1">
            <Skeleton animation="wave" />
          </Typography>
          <Styled.Description>
            <Skeleton animation="wave" />
          </Styled.Description>
        </Styled.Content>
      </Styled.Body>
    </Styled.Container>
  );
};

export type HeaderProps = {
  onClose: () => void;
  discountPercentage: number | undefined;
};

export const Header: React.FC<HeaderProps> = ({
  onClose,
  discountPercentage,
}) => {
  const { t } = useTranslation();
  const [themeMode] = useActiveTheme();

  return (
    <Styled.Container>
      {themeMode === ThemeModes.Dark ? (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-dark.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-dark@2x.png, /images/premium/purchase/premium-dialog-header-graphic-dark@3x.png"
        />
      ) : (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-light.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-light@2x.png, /images/premium/purchase/premium-dialog-header-graphic-light@3x.png"
        />
      )}

      <Styled.CloseButtonContainer>
        <IconButton
          icon={X}
          onClick={onClose}
          variant={Variants.Contained}
          size={Sizes.Large}
        />
      </Styled.CloseButtonContainer>

      <Styled.Body>
        <Styled.LogoContainer>
          <Icon icon={Logo} />

          <Styled.PremiumBatch>
            <Icon icon={Star} />
          </Styled.PremiumBatch>
        </Styled.LogoContainer>

        <Styled.Content>
          <Typography variant="h3" component="h1">
            {t(
              !!discountPercentage
                ? 'dialogs.premium.discount.title'
                : 'dialogs.premium.title',
              { percentage: discountPercentage },
            )}
          </Typography>
          <Styled.Description>
            {t(
              !!discountPercentage
                ? 'dialogs.premium.discount.slogan'
                : 'dialogs.premium.slogan',
              { percentage: discountPercentage },
            )}
          </Styled.Description>
        </Styled.Content>
      </Styled.Body>
    </Styled.Container>
  );
};
