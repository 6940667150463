import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import { CompleteSignupFormFields } from 'shared/types/complete-signup-form';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

import * as Styled from './complete-signup-form.style';
import { useCompleteSignupForm } from './use-complete-signup-form';

export type ResetPasswordFormProps = {
  email: string;
  onSubmit: (loginData: CompleteSignupFormFields) => void;
  isLoading: boolean;
  error?: ResetPasswordErrorTypes;
};

export const CompleteSignupForm: React.FC<ResetPasswordFormProps> = ({
  email,
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  const {
    register,
    onSubmit: submitForm,
    errors,
    watch,
  } = useCompleteSignupForm(onSubmit);

  const passwordValue = watch('password');
  const submitDisabled = isLoading || !passwordValue;

  const passwordErrorMessage = errors.password?.message
    ? t('forms.complete-signup.fields.password.error')
    : undefined;

  const nameErrorMessage = errors.name?.message
    ? t('forms.complete-signup.fields.name.error')
    : undefined;

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <FormInput
            {...register('name')}
            id="name"
            placeholder={t('forms.complete-signup.fields.name.placeholder')}
            disabled={isLoading}
          />
          {!!nameErrorMessage && (
            <Styled.ErrorMessage>{nameErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <FormInput
            value={email}
            id="email"
            placeholder={t('forms.complete-signup.fields.email.placeholder')}
            disabled
          />
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <FormInput
            {...register('password')}
            id="password"
            type="password"
            placeholder={t('forms.complete-signup.fields.password.placeholder')}
            error={passwordErrorMessage}
            disabled={isLoading}
          />
          {!!passwordErrorMessage && (
            <Styled.ErrorMessage>{passwordErrorMessage}</Styled.ErrorMessage>
          )}
          {error === ResetPasswordErrorTypes.Unknown && (
            <Styled.ErrorMessage>
              {t('forms.complete-signup.error.unknown')}
            </Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>
      </Styled.Fields>

      <Styled.Interactions>
        <Button
          variant={Variants.Contained}
          color={Colors.Primary}
          size={Sizes.Large}
          type="submit"
          disabled={submitDisabled}
          fullWidth
          alignCenter
          disabledBackgroundOnly={isLoading}
        >
          {isLoading ? (
            <Styled.Loader color="primary" size={20} />
          ) : (
            t('forms.complete-signup.buttons.submit.label')
          )}
        </Button>
      </Styled.Interactions>

      <Styled.TermsOfUse>
        {t('forms.terms-and-agreement.1')}
        <Styled.ExternalAnchor href="https://griply.app/eula" target="_blank">
          {t('forms.terms-and-agreement.terms-of-use')}
        </Styled.ExternalAnchor>
        {t('forms.terms-and-agreement.2')}
        <Styled.ExternalAnchor
          href="https://griply.app/privacy-policy"
          target="_blank"
        >
          {t('forms.terms-and-agreement.privacy-policy')}
        </Styled.ExternalAnchor>
        {t('forms.terms-and-agreement.3')}
      </Styled.TermsOfUse>
    </Styled.Form>
  );
};
