import { httpsCallable } from 'firebase/functions';
import { UtmParameters } from 'shared/types/utm-parameters';

import { getFunctions } from './helpers';

export type UtmValues = Record<UtmParameters, string>;

export const setUserUtmParameters = async (parameters: Partial<UtmValues>) => {
  if (!Object.values(parameters).length) {
    return;
  }

  const functions = getFunctions();
  const send = httpsCallable<Partial<UtmValues>>(
    functions,
    'users-setParametersOnUser',
  );

  await send(parameters);
};
