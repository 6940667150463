import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};
  width: 100%;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;

export const InputContainer = styled.div<{ $hasValue: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ $hasValue, theme }) =>
    `${theme.spacing(2.5)} ${theme.spacing($hasValue ? 3 : 8)} ${theme.spacing(2.5)} ${theme.spacing(3)}`};
  width: 100%;

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  color: ${({ $hasValue, theme }) =>
    $hasValue ? theme.palette.text.primary : theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const Input = styled.input`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  flex: 1;
  border: none;
  margin: 0;
  padding: 0;

  outline: none;
  background: transparent;

  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const ClearButton = styled.button`
  padding: 0;
  margin: 0;

  cursor: pointer;
  background: transparent;
  border: none;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: inherit;
`;
