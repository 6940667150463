import { Timestamp } from 'shared/types/timestamp';

export const getUniqueDayEntries = (dates: Timestamp[]) => {
  const uniqueDays = [];
  const seenDays = new Set();

  for (const date of dates) {
    const day = date.toISOString().split('T')[0]; // Extract date portion
    if (!seenDays.has(day)) {
      seenDays.add(day);
      uniqueDays.push(date);
    }
  }

  return uniqueDays;
};
