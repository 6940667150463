import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';
import { useTheme } from 'styled-components';

import { HeaderButton } from './header-button';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <PageHeader
        title={t('pages.goals.title')}
        interactions={theme.isMobile ? undefined : <HeaderButton />}
      />
    </>
  );
};
