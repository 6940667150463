import { httpsCallable } from 'firebase/functions';
import { PriceInterval } from 'shared/types/price';

import { getFunctions } from './helpers';

export const createStripeSessionUrl = async ({
  interval,
  c,
}: {
  interval: PriceInterval;
  c?: string;
}) => {
  const functions = getFunctions();
  const createSessionUrl = httpsCallable<{ type: string; c?: string }, string>(
    functions,
    'stripeCreateSession-createSession',
  );

  const { data } = await createSessionUrl({ type: interval, c });

  return data;
};
