import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';
import { useDelayedCallback } from 'shared/hooks/use-delayed-callback';

import { TemplateSearch } from '../template-search';

export type HeaderProps = {
  initialSearch: string;
  onSearch: (search: string) => void;
};

export const Header: React.FC<HeaderProps> = ({ initialSearch, onSearch }) => {
  const { t } = useTranslation();

  const onChangeSearch = useDelayedCallback(onSearch, 500);

  return (
    <>
      <PageHeader title={t('pages.goal-templates.title')} />
      <TemplateSearch initialValue={initialSearch} onChange={onChangeSearch} />
    </>
  );
};
