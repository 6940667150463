import { useRevalidator } from 'react-router-dom';
import { useResetPasswordMutation } from 'shared/hooks/queries/use-reset-password-mutation';

export const useResetPassword = () => {
  const { revalidate } = useRevalidator();
  const { mutateAsync, error, reset, isPending, isSuccess } =
    useResetPasswordMutation();

  const resetPassword = async ({
    password,
    code,
  }: {
    password: string;
    code: string;
  }) => {
    await mutateAsync({ password, code });
    revalidate();
  };

  return {
    resetPassword,
    error,
    isLoading: isPending,
    isSuccess,
    reset,
  };
};
