import { httpsCallable } from 'firebase/functions';
import { GoalTemplateCategoryWithGoalTemplates } from 'shared/types/goal-template-category';

import { getFunctions } from './helpers';

type RequestParameters = {};

type Result = {
  templateCategories: GoalTemplateCategoryWithGoalTemplates[];
};

export const getGoalTemplateCategories = async () => {
  const functions = getFunctions();
  const getGoalTemplates = httpsCallable<RequestParameters, Result>(
    functions,
    'templates-getAllPredefinedCategoriesWithGoalTemplates',
  );

  const { data } = await getGoalTemplates(
    JSON.stringify({ noDistanceFilter: true }),
  );

  return data.templateCategories;
};
