import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, X } from 'shared/assets/icons';
import { GridContainer } from 'shared/components/ui/grid-container';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './template-search.style';

export type TemplateSearchProps = {
  onChange: (search: string) => void;
  initialValue: string;
};

export const TemplateSearch: React.FC<TemplateSearchProps> = ({
  onChange,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [input, setInput] = React.useState(initialValue);

  const _onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setInput(target.value);
    onChange(target.value);
  };

  const clear = () => {
    setInput('');
    onChange('');
  };

  return (
    <GridContainer>
      <Styled.Container>
        <Styled.InputContainer $hasValue={!!input}>
          <Styled.IconContainer>
            <Icon icon={Search} />
          </Styled.IconContainer>
          <Styled.Input
            value={input}
            onChange={_onChange}
            placeholder={t('pages.goal-templates.search.placeholder.label')}
          />
          {!!input && (
            <Styled.ClearButton onClick={clear} tabIndex={-1}>
              <Icon icon={X} />
            </Styled.ClearButton>
          )}
        </Styled.InputContainer>
      </Styled.Container>
    </GridContainer>
  );
};
