import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import { LoadingHeader } from './header';
import { LoadingPaymentOptions } from './payment-options';
import * as Styled from './purchase-premium.style';
import { UspList } from './usp-list';
import { WarningDialog } from './warning-dialog';

export type LoadingPurchasePremiumProps = {
  onClose: () => void;
};

export const LoadingPurchasePremium: React.FC<LoadingPurchasePremiumProps> = ({
  onClose,
}) => {
  const [warningOpen, setWarningOpen] = useState(false);

  const _onClose = () => {
    if (!warningOpen) {
      setWarningOpen(true);
      return;
    }

    setWarningOpen(false);
    onClose();
  };

  const onCancelWarning = () => {
    setWarningOpen(false);
  };
  return (
    <>
      <Styled.Container>
        <LoadingHeader onClose={_onClose} />

        <Styled.Body>
          <UspList />

          <Styled.PaymentForm>
            <Styled.PaymentOptions>
              <LoadingPaymentOptions />
            </Styled.PaymentOptions>

            <Styled.Interactions>
              <Button
                variant={Variants.Contained}
                size={Sizes.Large}
                color={Colors.Primary}
                disabled
                fullWidth
                alignCenter
                disabledBackgroundOnly
              >
                <Styled.Loader color="primary" size={20} />
              </Button>
              <Typography variant="overline">
                <Skeleton animation="wave" />
              </Typography>
            </Styled.Interactions>
          </Styled.PaymentForm>
        </Styled.Body>
      </Styled.Container>
      <WarningDialog
        open={warningOpen}
        onSubmit={onCancelWarning}
        onClose={_onClose}
      />
    </>
  );
};
