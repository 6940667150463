import { addDays, endOfDay } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionPlanGoalHeader } from 'shared/components/ui/action-plan-goal-header';
import { Collapse } from 'shared/components/ui/collapse';
import { useToday } from 'shared/contexts/today';
import { useToggle } from 'shared/hooks/use-toggle';
import {
  GoalTemplateInstance,
  TaskTemplateInstance,
} from 'shared/types/goal-template';
import { LifeArea } from 'shared/types/life-area';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import { GoalTemplatePreviewActionPlan } from './goal-template-preview-action-plan';
import * as Styled from './goal-template-preview-action-plan.style';

type GoalProps = {
  goal: GoalTemplateInstance;

  tasks: TaskTemplateInstance[];

  lifeAreas: LifeArea[];
  allGoals: GoalTemplateInstance[];
};

const Goal: React.FC<GoalProps> = ({ goal, tasks, lifeAreas, allGoals }) => {
  const { i18n } = useTranslation();
  const today = useToday();

  const [collapseOpen, toggleCollapse] = useToggle();

  const goalColor =
    getGoalColor(goal.id, { goals: allGoals, lifeAreas }) ?? undefined;
  return (
    <>
      <ActionPlanGoalHeader
        id={goal.id}
        name={getLocalizedStringValue(goal.name, i18n.language)}
        iconName={goal.iconName}
        color={goalColor}
        startDate={
          typeof goal.daysTillStartDate === 'number'
            ? addDays(endOfDay(today), goal.daysTillStartDate)
            : null
        }
        deadline={
          typeof goal.daysTillDeadline === 'number'
            ? addDays(endOfDay(today), goal.daysTillDeadline)
            : null
        }
        metric={goal.metric}
        onOpen={toggleCollapse}
        isOpen={collapseOpen}
        onPremium={() => {}}
        isPreview
      />
      <Collapse isOpen={collapseOpen}>
        <Styled.SublistContainer $maximalIndentation>
          <GoalTemplatePreviewActionPlan
            tasks={tasks}
            mainGoal={goal}
            lifeAreas={lifeAreas}
            goals={allGoals}
            noRightSpacing
          />
        </Styled.SublistContainer>
      </Collapse>
    </>
  );
};

export type GoalListProps = {
  goals: GoalTemplateInstance[];
  tasks: TaskTemplateInstance[];

  noRightSpacing?: boolean;

  lifeAreas: LifeArea[];
  allGoals: GoalTemplateInstance[];
};

export const GoalList: React.FC<GoalListProps> = ({
  goals,
  tasks,
  noRightSpacing,

  lifeAreas,
  allGoals,
}) => (
  <Styled.List $noRightSpacing={!!noRightSpacing}>
    {goals.map((goal) => (
      <Styled.ListItem key={goal.id}>
        <Goal
          goal={goal}
          allGoals={allGoals}
          tasks={tasks}
          lifeAreas={lifeAreas}
        />
      </Styled.ListItem>
    ))}
  </Styled.List>
);
