import { useMemo } from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

import { useGoals } from './use-goals';

export const useCreateUnlimitedGoalsAllowed = () => {
  const goals = useGoals();

  const filteredGoals = useMemo(
    () =>
      goals.filter(
        ({
          completedAt,
          parentIds,
          frozenAt,
          archivedAt,
          excludedFromLimits,
        }) =>
          !excludedFromLimits &&
          !completedAt &&
          !frozenAt &&
          !archivedAt &&
          !parentIds?.length,
      ),
    [goals],
  );

  const { allowed, enforced, limit } = usePremiumFeatureAllowed(
    LimitKeys.CreateGoal,
    {
      totalUncompleted: filteredGoals.length,
    },
  );

  return {
    allowed,
    limit,
    enforced,
    total: filteredGoals.length,
  };
};
