import { Day } from 'date-fns';
import { LimitKeys } from 'shared/types/limit-keys';
import { WeekDays } from 'shared/types/week-days';

export { version } from '../../package.json';

export const iosDownloadLink =
  'https://apps.apple.com/us/app/griply-control-your-life/id1556692747';

export const unlimitedLimit = -1;

export const defaultLimitKeyMap: Record<LimitKeys, number> = {
  [LimitKeys.CreateGoal]: 2,
  [LimitKeys.CreateHabit]: 2,
  [LimitKeys.HabitIcons]: 0,
  [LimitKeys.GoalUserPhoto]: 0,
  [LimitKeys.BiometricAppLock]: 0,
  [LimitKeys.CustomLifeAreas]: 0,
  [LimitKeys.CustomizeDefaultLifeAreas]: 0,
  [LimitKeys.VisionUserPhoto]: 0,
  [LimitKeys.SubGoals]: 0,
  [LimitKeys.Insights]: 0,
};

export const premiumLimitKeyMap: Record<LimitKeys, number> = {
  [LimitKeys.CreateGoal]: unlimitedLimit,
  [LimitKeys.CreateHabit]: unlimitedLimit,
  [LimitKeys.HabitIcons]: unlimitedLimit,
  [LimitKeys.GoalUserPhoto]: unlimitedLimit,
  [LimitKeys.BiometricAppLock]: unlimitedLimit,
  [LimitKeys.CustomLifeAreas]: unlimitedLimit,
  [LimitKeys.CustomizeDefaultLifeAreas]: unlimitedLimit,
  [LimitKeys.VisionUserPhoto]: unlimitedLimit,
  [LimitKeys.SubGoals]: unlimitedLimit,
  [LimitKeys.Insights]: unlimitedLimit,
};

export const weekdaysNumberMap: Record<WeekDays, Day> = {
  [WeekDays.Monday]: 1,
  [WeekDays.Tuesday]: 2,
  [WeekDays.Wednesday]: 3,
  [WeekDays.Thursday]: 4,
  [WeekDays.Friday]: 5,
  [WeekDays.Saturday]: 6,
  [WeekDays.Sunday]: 0,
};

export const reminderTimes = ['09:00', '12:00', '15:00', '18:00', '21:00'];

export const sideNavigationHoverClass = 'side-navigation-show-on-hover';

export const whatsappLink = 'https://chat.whatsapp.com/GSri97x51ubEMEIwccPICr';

export const subTaskLevel = 1;

export const PredefinedColorOptions = [
  {
    label: 'scarlet-flame',
    value: '#FA2835',
  },
  {
    label: 'strawberry-sorbet',
    value: '#FF6E77',
  },
  {
    label: 'blush-pink',
    value: '#FFA8AD',
  },
  {
    label: 'citrus-ember',
    value: '#FF6B1C',
  },
  {
    label: 'sunset-coral',
    value: '#FBA575',
  },
  {
    label: 'toasted-peach',
    value: '#F3BD96',
  },
  {
    label: 'golden-marigold',
    value: '#F7B10C',
  },
  {
    label: 'sunshine-gold',
    value: '#FBCA75',
  },
  {
    label: 'honey-glow',
    value: '#F3E396',
  },
  {
    label: 'verdant-leaf',
    value: '#57AC40',
  },
  {
    label: 'herbal-sage',
    value: '#98BD82',
  },
  {
    label: 'frosted-mint',
    value: '#C7E0C3',
  },
  {
    label: 'ocean-teal',
    value: '#07C49B',
  },
  {
    label: 'seafoam-mist',
    value: '#86C2B6',
  },
  {
    label: 'mint-whisper',
    value: '#ACE1D6',
  },
  {
    label: 'electric-sapphire',
    value: '#066DFE',
  },
  {
    label: 'azure-wave',
    value: '#599EFF',
  },
  {
    label: 'cerulean-breeze',
    value: '#9DC6FF',
  },
  {
    label: 'royal-amethyst',
    value: '#671DD3',
  },
  {
    label: 'vivid-periwinkle',
    value: '#8875FB',
  },
  {
    label: 'cool-sky',
    value: '#96AAF3',
  },
  {
    label: 'fuchsia-rose',
    value: '#FF236F',
  },
  {
    label: 'cotton-candy',
    value: '#FF98B7',
  },
  {
    label: 'petal-pink',
    value: '#F2C5D7',
  },
  {
    label: 'midnight-indigo',
    value: '#2C2E69',
  },
  {
    label: 'dusky-lavender',
    value: '#6A6693',
  },
  {
    label: 'lavender-mist',
    value: '#B5B2CF',
  },
  {
    label: 'cinnamon-oak',
    value: '#866E53',
  },
  {
    label: 'autumn-taupe',
    value: '#A1907E',
  },
  {
    label: 'vintage-sand',
    value: '#C2B59D',
  },
  {
    label: 'deep-charcoal',
    value: '#2C2A24',
  },
  {
    label: 'steel-gray',
    value: '#8A8A8A',
  },
  {
    label: 'silvery-cloud',
    value: '#C7C7C7',
  },
];
