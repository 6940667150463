import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect, Option } from 'shared/components/ui/form-select';
import { RadioInput } from 'shared/components/ui/radio-input';
import { WeekDays } from 'shared/types/week-days';
import { getWeekDays } from 'shared/utils/get-week-days';
import { isNumber } from 'shared/utils/is-number';

import { getMonthDayOrdinals } from './get-month-day-ordinals';
import {
  parseMonthDaysFromRrule,
  parseMonthWeekDayFromRrule,
  setMonthDaysInRrule,
  setMonthSpecificDayInRrule,
} from './parse-rrule';
import * as Styled from './repeat-rrule-form.style';
import { DayType } from './types';

enum RadioOptions {
  SpecificDays = 'specific-days',
  SpecificDay = 'specific-day',
  TimesPerMonth = 'times-per-month',
}

const timesPerMonthOptions: Option[] = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
];

const monthDays: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
const ordinals = [1, 2, 3, 4, 5, -1];

export type MonthsFormProps = {
  rrule: string;
  count: number | null;
  onChange: (monthValues: { value: string; count: number | null }) => void;
  weekStartsOn: WeekDays;
};

export const MonthsForm: React.FC<MonthsFormProps> = ({
  rrule,
  count,
  weekStartsOn,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedMonthDays = useMemo(
    () => parseMonthDaysFromRrule(rrule),
    [rrule],
  );
  const selectedWeekDay = useMemo(
    () => parseMonthWeekDayFromRrule(rrule),
    [rrule],
  );
  const initialOrdinalWeekDay = useMemo(
    () => getWeekDays(weekStartsOn)[0],
    [weekStartsOn],
  );
  const timesPerWeekSelected = isNumber(count);
  const specificMonthDaysSelected = !timesPerWeekSelected && !!selectedWeekDay;
  const specificDaysSelected = !timesPerWeekSelected && !selectedWeekDay;

  const onChangeOption = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value === RadioOptions.SpecificDays) {
      return onChange({ value: setMonthDaysInRrule([]), count: null });
    }
    if (target.value === RadioOptions.SpecificDay) {
      return onChange({
        value: setMonthSpecificDayInRrule({
          weekDay: initialOrdinalWeekDay,
          ordinal: ordinals[0],
        }),
        count: null,
      });
    }

    onChange({ value: setMonthDaysInRrule([]), count: 1 });
  };

  const onChangeMonthDay = (e: React.MouseEvent) => {
    e.preventDefault();
    const dayValue = e.currentTarget.getAttribute('data-value');
    const dayNumber = parseInt(dayValue ?? '');

    const newSelectedMonthDays = selectedMonthDays.filter(
      (monthDay) => monthDay !== dayNumber,
    );
    if (newSelectedMonthDays.length === selectedMonthDays.length) {
      newSelectedMonthDays.push(dayNumber);
    }

    onChange({
      value: setMonthDaysInRrule(newSelectedMonthDays),
      count: null,
    });
  };

  const onChangeOrdinalDay = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange({
      value: setMonthSpecificDayInRrule({
        weekDay: e.target.value as DayType | WeekDays,
        ordinal: selectedWeekDay.ordinal,
      }),
      count: null,
    });
  };

  const onChangeOrdinalNumber = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange({
      value: setMonthSpecificDayInRrule({
        weekDay: selectedWeekDay.weekDay,
        ordinal: parseInt(e.target.value),
      }),
      count: null,
    });
  };

  const onChangeCount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCount = parseInt(e.target.value);
    onChange({
      value: setMonthDaysInRrule([]),
      count: isNaN(newCount) ? 0 : newCount,
    });
  };

  const ordinalOptions = useMemo(
    () =>
      ordinals.map((ordinal) => ({
        label: t(
          `general.labels.habit.repeat.value.ordinal.specific-day.${ordinal}.label`,
        ),
        value: ordinal,
      })),
    [t],
  );

  const ordinalDayOptions = useMemo(
    () =>
      getMonthDayOrdinals(weekStartsOn).map((weekDay) => ({
        label: t(
          `general.labels.habit.repeat.specific-days.days.${weekDay}.label`,
        ),
        value: weekDay,
      })),
    [t, weekStartsOn],
  );

  const daysDescription = useMemo(() => {
    const lastSelectedDays = [29, 30, 31].filter((day) =>
      selectedMonthDays.includes(day),
    );

    if (!lastSelectedDays.length) {
      return;
    }

    if (lastSelectedDays.length > 1) {
      const lastEntry = lastSelectedDays[lastSelectedDays.length - 1];
      const firstEntries = lastSelectedDays.slice(0, -1);

      return t(
        'forms.habit.fields.repeat.months.specific-days.last-day.label',
        {
          days: [
            firstEntries
              .map((day) => t(`general.labels.${day}.ordinal`))
              .join(', '),
            t('general.labels.and'),
            t(`general.labels.${lastEntry}.ordinal`),
          ].join(' '),
          amount: lastEntry,
          count: lastSelectedDays.length,
        },
      );
    }

    return t('forms.habit.fields.repeat.months.specific-days.last-day.label', {
      days: t(`general.labels.${lastSelectedDays[0]}.ordinal`),
      amount: lastSelectedDays[0],
      count: 1,
    });
  }, [selectedMonthDays, t]);

  return (
    <Styled.FormContainer>
      {/* Specific day */}
      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.SpecificDays}
          name="month-options"
          checked={specificDaysSelected}
          onChange={onChangeOption}
          value={RadioOptions.SpecificDays}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.SpecificDays}
          >
            {t('forms.habit.fields.repeat.months.specific-days.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={!!selectedWeekDay}>
          <Styled.MonthDayList>
            {monthDays.map((monthDay) => (
              <Styled.MonthDayListItem key={monthDay}>
                <Styled.DayButton
                  onClick={onChangeMonthDay}
                  data-value={monthDay}
                  $selected={selectedMonthDays.includes(monthDay)}
                  disabled={!!selectedWeekDay}
                  type="button"
                >
                  {monthDay}
                </Styled.DayButton>
              </Styled.MonthDayListItem>
            ))}
          </Styled.MonthDayList>
          {!!daysDescription && (
            <Styled.MonthDescription>{daysDescription}</Styled.MonthDescription>
          )}
        </Styled.RadioBody>
      </Styled.RadioContainer>

      {/* based-on-month day */}
      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.SpecificDay}
          name="month-options"
          checked={specificMonthDaysSelected}
          onChange={onChangeOption}
          value={RadioOptions.SpecificDay}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.SpecificDay}
          >
            {t('forms.habit.fields.repeat.months.specific-day.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={false}>
          <Styled.TimesPerContainer>
            <FormSelect
              value={selectedWeekDay?.ordinal}
              options={ordinalOptions}
              onChange={onChangeOrdinalNumber}
              disabled={!selectedWeekDay}
              isSmall
            />

            <FormSelect
              value={selectedWeekDay?.weekDay}
              options={ordinalDayOptions}
              onChange={onChangeOrdinalDay}
              disabled={!selectedWeekDay}
              isSmall
            />
          </Styled.TimesPerContainer>
        </Styled.RadioBody>
      </Styled.RadioContainer>

      {/* Times per month */}
      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.TimesPerMonth}
          name="month-options"
          checked={timesPerWeekSelected}
          onChange={onChangeOption}
          value={RadioOptions.TimesPerMonth}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.TimesPerMonth}
          >
            {t('forms.habit.fields.repeat.months.times-per-month.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={!timesPerWeekSelected}>
          <Styled.TimesPerContainer>
            <FormSelect
              options={timesPerMonthOptions}
              value={count ?? ''}
              onChange={onChangeCount}
              disabled={!timesPerWeekSelected}
              isSmall
            />
            <Typography variant="body2">
              {t('forms.habit.fields.repeat.months.times-per-month.post-fix', {
                count: count ?? 1,
              })}
            </Typography>
          </Styled.TimesPerContainer>
        </Styled.RadioBody>
      </Styled.RadioContainer>
    </Styled.FormContainer>
  );
};
