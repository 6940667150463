import Typography from '@mui/material/Typography';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DotsMenuFat } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { isNumber } from 'shared/utils/is-number';

import { ProgressChartContextMenu } from '../progress-chart-context-menu';
import * as Styled from './progress-chart-container.style';

export type ProgressChartContainerProps = {
  target?: number;
  lastEntryValue?: number;
  targetFormat?: string;
  onUpdateProgress?: () => void;
  onShowHistory?: () => void;
  onEditTarget?: () => void;
  onEditStartDate?: () => void;
  onEditDeadline?: () => void;
  onComplete?: () => void;
  isCompleted?: boolean;
  children: React.ReactNode;
};

export const ProgressChartContainer: React.FC<ProgressChartContainerProps> = ({
  target,
  lastEntryValue,
  targetFormat = metricUnitFormatBase,
  onUpdateProgress,
  onShowHistory,
  onEditTarget,
  onEditStartDate,
  onEditDeadline,
  onComplete,
  isCompleted,
  children,
}) => {
  const { t } = useTranslation();
  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const hasContextMenu = useMemo(
    () =>
      !!onUpdateProgress ||
      !!onShowHistory ||
      !!onEditTarget ||
      !!onEditStartDate ||
      !!onEditDeadline ||
      !!onComplete,
    [
      onComplete,
      onEditDeadline,
      onEditStartDate,
      onEditTarget,
      onShowHistory,
      onUpdateProgress,
    ],
  );

  const formattedTarget = targetFormat.replace(
    metricUnitFormatBase,
    [
      isNumber(lastEntryValue) && formatNumberShort(lastEntryValue!),
      isNumber(target) && formatNumberShort(target!),
    ]
      .filter(Boolean)
      .join('/'),
  );

  return (
    <>
      <Styled.Container>
        <Styled.Header>
          <Styled.TargetContainer>
            <Typography variant="subtitle2">
              {isNumber(target)
                ? t('pages.goal-detail.progress.target.label', {
                    target: formattedTarget,
                  })
                : t('pages.goal-detail.progress.open-target.label')}
            </Typography>

            {hasContextMenu && (
              <Styled.IconButtonContainer>
                <IconButton
                  size={IconButtonSizes.Large}
                  icon={DotsMenuFat}
                  onClick={openMenu}
                  ref={iconButtonRef}
                />
              </Styled.IconButtonContainer>
            )}
          </Styled.TargetContainer>

          {!!onUpdateProgress && (
            <Styled.DesktopButtonContainer>
              <Button
                onClick={onUpdateProgress}
                variant={Variants.Text}
                size={Sizes.Medium}
                color={Colors.Primary}
              >
                {t('pages.goal-detail.progress.update-progress.button.label')}
              </Button>
            </Styled.DesktopButtonContainer>
          )}
        </Styled.Header>

        <Styled.Body>
          <Styled.ChartContainer>{children}</Styled.ChartContainer>
        </Styled.Body>

        {!!onUpdateProgress && (
          <Styled.MobileButtonContainer>
            <Button
              onClick={onUpdateProgress}
              variant={Variants.Contained}
              size={Sizes.Medium}
              color={Colors.Primary}
            >
              {t('pages.goal-detail.progress.update-progress.button.label')}
            </Button>
          </Styled.MobileButtonContainer>
        )}
      </Styled.Container>

      {menuOpen && (
        <ProgressChartContextMenu
          onClose={closeMenu}
          onUpdateProgress={onUpdateProgress}
          onShowHistory={onShowHistory}
          onEditTarget={onEditTarget}
          onEditStartDate={onEditStartDate}
          onEditDeadline={onEditDeadline}
          onComplete={onComplete}
          isCompleted={isCompleted}
          referenceElement={iconButtonRef}
        />
      )}
    </>
  );
};
