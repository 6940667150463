import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  min-height: 32rem;
`;

export const Container = styled.div`
  margin: ${({ theme }) => `${theme.spacing(6)} 0`};
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(3)};

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
  width: 100%;

  list-style: none;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`0 ${theme.spacing(3)}`};
    `};
`;

export const ListItem = styled.li`
  min-width: 0;
`;
