import { addDays, endOfDay } from 'date-fns';
import React from 'react';
import { TaskPreviewCard } from 'shared/components/ui/task-preview-card';
import { useToday } from 'shared/contexts/today';
import { TaskTemplateInstance } from 'shared/types/goal-template';

import * as Styled from './goal-template-preview-action-plan.style';

export type TasksListProps = {
  tasks: TaskTemplateInstance[];
  noRightSpacing?: boolean;
  hasIndentation?: boolean;
};

export const TasksList: React.FC<TasksListProps> = ({
  tasks,
  noRightSpacing,
  hasIndentation,
}) => {
  const today = useToday();

  return (
    <Styled.List $noRightSpacing={!!noRightSpacing}>
      {tasks.map(
        ({
          id,
          name,
          description,
          priority,
          daysTillDeadline,
          reminderTime,
          schedule,
          childIds,
        }) => (
          <Styled.ListItem key={id} $hasIndentation={!!hasIndentation}>
            <TaskPreviewCard
              name={name}
              description={description}
              priority={priority}
              deadline={
                typeof daysTillDeadline === 'number'
                  ? addDays(endOfDay(today), daysTillDeadline)
                  : null
              }
              reminderTime={reminderTime}
              schedule={schedule}
              childIds={childIds}
              today={today}
            />
          </Styled.ListItem>
        ),
      )}
    </Styled.List>
  );
};
