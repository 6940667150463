import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconContainer = styled.span<{ $hasLargeIcon: boolean }>`
  font-size: ${({ $hasLargeIcon }) => ($hasLargeIcon ? '114.3%' : '100%')};
  line-height: 62.5%;
`;

export const Label = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;
