import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  min-height: 22.8rem;
  height: 22.8rem;

  padding: ${({ theme }) => theme.spacing(6)};

  background: ${({ theme }) => theme.gradients.premium};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    padding: ${({ theme }) => theme.spacing(10)};
    min-height: 26.8rem;
    height: 26.8rem;
    overflow: hidden;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(3.5)};
  right: ${({ theme }) => theme.spacing(3.5)};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Image = styled.img`
  display: none;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;

    transform: translateX(-50%);
  }
`;

export const LogoContainer = styled.span`
  display: block;
  position: relative;

  width: 4rem;

  font-size: 4rem;
  line-height: 62.5%;
`;

export const PremiumBatch = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  transform: translate(50%, 50%);
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.warning.main};

  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  fill: ${({ theme }) => theme.palette.common.white};
  line-height: 62.5%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.common.white};
`;

export const Description = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  margin: 0;
  padding: 0;

  opacity: 0.6;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};

  width: 28rem;
`;
