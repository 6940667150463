import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { getFrequencyDueDateBySchedule } from './get-frequency-due-date-by-schedule';
import { getRruleDueDateBySchedule } from './get-rrule-due-date-by-schedule';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

export const getScheduleDueDate = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (schedule.frequency?.count) {
    return getFrequencyDueDateBySchedule(schedule, { today, weekStartsOn });
  }

  return getRruleDueDateBySchedule(schedule, { today, weekStartsOn });
};
