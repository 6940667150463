import { useCallback } from 'react';
import { useStripeSessionUrlMutation } from 'shared/hooks/queries/use-stripe-session-url-mutation';
import { PriceInterval } from 'shared/types/price';

export const useRedirectToStripeUrl = () => {
  const { mutateAsync, isPending } = useStripeSessionUrlMutation();

  const redirect = useCallback(
    async ({
      interval,
      discountCode,
    }: {
      interval: PriceInterval;
      discountCode?: string;
    }) => {
      window.location.href = await mutateAsync({
        interval,
        discountCode,
      });
      // prevent loading-state to change when the browser is slow
      return new Promise(() => {});
    },
    [mutateAsync],
  );

  return {
    redirect,
    isLoading: isPending,
  };
};
