import Rollbar from 'rollbar';
import { version } from 'shared/constants';

export { getLoggableError } from './get-loggable-error';

let rollbar: Rollbar;

const rollbarConfig: Rollbar.Configuration = {
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',

  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  code_version: import.meta.env.VITE_ROLLBAR_CODE_VERSION,

  captureIp: 'anonymize',
  captureUsername: false,
  captureEmail: false,
  captureUncaught: true,
  captureUnhandledRejections: true,

  addErrorContext: true,

  itemsPerMinute: 10,
  maxItems: 20,
  payload: {
    appVersion: version,
  },
};

export const init = () => {
  if (rollbar) {
    return rollbar;
  }

  rollbar = new Rollbar(rollbarConfig);
};

export const getRollbar = () => {
  if (!rollbar) {
    rollbar = new Rollbar(rollbarConfig);
  }

  return rollbar;
};
