import { useSortable } from '@dnd-kit/sortable';
import {
  addDays,
  endOfDay,
  endOfWeek,
  format,
  isAfter,
  isSameDay,
  isSameWeek,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';
import {
  Check,
  ChevronLeft,
  IconFormIcons,
  IconFormNames,
  Lock,
} from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { Goal } from 'shared/types/goal';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getRruleFrequency } from 'shared/utils/get-rrule-dates';
import { getScheduleTranslationKey } from 'shared/utils/get-schedule-translation-key';

import * as Styled from './habit-detail-mobile-card.style';

type SortableContainerProps = React.ComponentProps<typeof Styled.Container> & {
  id: ID;
};

const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  ...rest
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.Container
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

export type HabitDetailMobileCardProps = {
  id: ID;
  icon?: IconFormNames | null;
  name: string;
  schedules: HabitSchedule[];
  goalId?: ID | null;
  goals: Goal[];
  lifeAreaId?: ID | null;
  lifeAreas: LifeArea[];
  weekStartsOn: WeekDays;
  onCompleteDate?: (id: ID, date: Timestamp) => void;
  onEditHabit?: (id: ID) => void;
  isFrozen?: boolean;
  isCompleted?: boolean;
  isArchived?: boolean;
  isSortable?: boolean;
};

export const HabitDetailMobileCard: React.FC<HabitDetailMobileCardProps> = ({
  id,
  name,
  icon,
  schedules,
  goalId,
  goals,
  lifeAreaId,
  lifeAreas,
  weekStartsOn,
  onCompleteDate,
  onEditHabit,
  isFrozen,
  isCompleted,
  isArchived,
  isSortable,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const Container = isSortable ? SortableContainer : Styled.Container;

  const weekStart = useMemo(
    () =>
      startOfWeek(today, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }),
    [today, weekStartsOn],
  );

  const weekDays = useMemo(
    () =>
      Array.from({ length: 7 }, (_, dayIndex) => ({
        date: addDays(weekStart, dayIndex),
      })),
    [weekStart],
  );

  const activeSchedule = useMemo(
    () => schedules.find(({ endDate }) => !endDate),
    [schedules],
  );

  const percentage = useMemo(() => {
    if (!activeSchedule) {
      return 0;
    }

    const completedDates =
      activeSchedule.completions.filter((completion) =>
        isSameWeek(weekStart, completion, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      ) ?? [];

    const frequency = Math.max(
      1,
      activeSchedule.frequency?.count ??
        getRruleFrequency({
          format: activeSchedule.rrule.format,
          startDate: startOfWeek(weekStart, {
            weekStartsOn: weekdaysNumberMap[weekStartsOn],
          }),
          endDate: endOfWeek(weekStart, {
            weekStartsOn: weekdaysNumberMap[weekStartsOn],
          }),
        }),
    );

    return Math.min(
      Math.max(Math.round((completedDates.length / frequency) * 100), 0),
      100,
    );
  }, [activeSchedule, weekStart, weekStartsOn]);

  const activeLinkedItem = useMemo(
    () =>
      goals.find(({ id }) => goalId === id) ??
      lifeAreas.find(({ id }) => id === lifeAreaId),
    [goals, lifeAreas, goalId, lifeAreaId],
  );
  const linkedItemColor = useMemo(
    () =>
      activeLinkedItem
        ? getGoalColor(activeLinkedItem.id, { goals, lifeAreas })
        : undefined,
    [activeLinkedItem, goals, lifeAreas],
  );

  const _onEdit = () => onEditHabit?.(id);

  return (
    <Container id={id} $isDisabled={!!isFrozen || !!isArchived}>
      <Styled.Header onClick={_onEdit}>
        <Styled.IconContainer>
          <Icon icon={IconFormIcons[icon ?? IconFormNames.Repeat]} />

          {isCompleted && (
            <Styled.CompletedContainer>
              <Icon icon={Check} />
            </Styled.CompletedContainer>
          )}
          {!!linkedItemColor && <Styled.ColorDot $color={linkedItemColor} />}
        </Styled.IconContainer>

        <Styled.HeaderContent>
          <Styled.TitleContainer $isFrozen={!!isFrozen}>
            <Styled.Title>{name}</Styled.Title>
            <Styled.ChevronContainer>
              <Icon icon={ChevronLeft} />
            </Styled.ChevronContainer>
          </Styled.TitleContainer>

          <Styled.HeaderMetaContainer>
            {!!activeSchedule && (
              <Styled.ProgressContainer>
                <CircleProgress percentage={percentage} isSecondary isSmall />
              </Styled.ProgressContainer>
            )}
            {(!!activeSchedule || !!activeLinkedItem) && <Styled.Dot />}
            {!!activeSchedule && (
              <Styled.MetaSpan>
                {getScheduleTranslationKey(activeSchedule, t)}
              </Styled.MetaSpan>
            )}
            {!!activeSchedule && !!activeLinkedItem && <Styled.Dot />}
            {!!activeLinkedItem && (
              <Styled.MetaSpan>{activeLinkedItem.name}</Styled.MetaSpan>
            )}
          </Styled.HeaderMetaContainer>
        </Styled.HeaderContent>

        {isFrozen && (
          <Styled.StateIcon>
            <Icon icon={Lock} />
          </Styled.StateIcon>
        )}
      </Styled.Header>

      <Styled.WeeksList>
        {weekDays.map(({ date }) => {
          const _onCompleteDate = () => onCompleteDate?.(id, date);
          const rrule = RRule.fromString(activeSchedule?.rrule.format ?? '');
          rrule.options.dtstart = endOfDay(subDays(date, 1));
          const isValidRruleDate =
            rrule.between(startOfDay(date), endOfDay(date), true).length > 0;

          return (
            <Styled.WeekListItem key={date.getTime()}>
              <Styled.WeekButton
                $checked={
                  !!activeSchedule?.completions?.some((completedDate) =>
                    isSameDay(completedDate, date),
                  )
                }
                $isRecommended={isValidRruleDate}
                disabled={!isSameDay(date, today) && isAfter(date, today)}
                onClick={_onCompleteDate}
              >
                {format(date, 'EEEEE')}
              </Styled.WeekButton>
            </Styled.WeekListItem>
          );
        })}
      </Styled.WeeksList>
    </Container>
  );
};
