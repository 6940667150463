import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReorderDotsVertical } from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import { IconButton } from 'shared/components/ui/icon-button';
import { SmallScreenGoalCard } from 'shared/components/ui/small-screen-goal-card';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import { columnToCellFcMap } from './column-to-cell-fc-map';
import * as Styled from './goal-data-grid.style';
import { MenuCell } from './menu-cell';
import { Column, ColumnType, RowData } from './types';

type SortableDataRowProps = React.ComponentProps<typeof Styled.DataRow> & {
  id: ID;
};

const SortableDataRow: React.FC<SortableDataRowProps> = ({ id, ...rest }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.DataRow
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

const Container: React.FC<{
  id: ID;
  isFrozen?: boolean;
  onClick?: (id: ID) => void;
  children: React.ReactNode;
}> = ({ id, isFrozen, onClick, children }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (isFrozen || onClick) {
    const _onClick = () => onClick?.(id);
    return (
      <Styled.RowContainer onClick={_onClick}>{children}</Styled.RowContainer>
    );
  }

  if (theme.isTouchDevice) {
    const nav = () => navigate(replaceUrlParams(Paths.GoalDetail, { id }));
    return <Styled.RowContainer onClick={nav}>{children}</Styled.RowContainer>;
  }

  return (
    <Styled.RowLink to={replaceUrlParams(Paths.GoalDetail, { id })}>
      {children}
    </Styled.RowLink>
  );
};

export type DataRowProps = {
  columns: Column[];
  data: RowData;
  onGoal?: (id: ID) => void;
  isArchived?: boolean;
  isSortable?: boolean;
  isFrozen?: boolean;
};

export const DataRow: React.FC<DataRowProps> = ({
  data,
  columns,
  onGoal,
  isArchived,
  isSortable,
  isFrozen,
}) => {
  const theme = useTheme();
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({ disabled: isFrozen });
  const DataRow = isSortable ? SortableDataRow : Styled.DataRow;
  const emptyExcludedColumns = useMemo(
    () => columns.filter(({ type }) => type !== ColumnType.Empty),
    [columns],
  );
  const shouldShowEmptyColumn = emptyExcludedColumns.length < columns.length;

  return (
    <>
      <DataRow
        id={data.id}
        onContextMenu={openMenu}
        $selected={!!menuPosition}
        $isArchived={!!isArchived}
      >
        <Container id={data.id} isFrozen={isFrozen} onClick={onGoal}>
          <Styled.MobileContainer $selected={!!menuPosition}>
            <SmallScreenGoalCard
              name={data.name}
              image={data.image}
              iconName={data.iconName}
              color={data.color}
              completion={data.completion}
              deadline={data.deadline}
              isCompleted={!!data.completedAt}
              isFrozen={isFrozen}
              isArchived={isArchived}
            />
          </Styled.MobileContainer>

          <Styled.DesktopContainer $selected={!!menuPosition}>
            {!!isSortable && (
              <Styled.DragHandle>
                <IconButton icon={ReorderDotsVertical} />
              </Styled.DragHandle>
            )}
            {emptyExcludedColumns.map(({ type }) => {
              const DataCell = columnToCellFcMap[type];

              return (
                <Styled.DataRowItem key={type} $type={type}>
                  <DataCell data={data} isFrozen={isFrozen} />
                </Styled.DataRowItem>
              );
            })}

            {shouldShowEmptyColumn && (
              <Styled.DataRowItem $type={ColumnType.Empty}>
                <MenuCell id={data.id} isFrozen={isFrozen} />
              </Styled.DataRowItem>
            )}
          </Styled.DesktopContainer>
        </Container>
      </DataRow>

      {!!menuPosition && !theme.isMobile && (
        <GoalContextMenu
          id={data.id}
          location={menuPosition}
          onClose={closeMenu}
        />
      )}
    </>
  );
};
