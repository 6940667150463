import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sizes, Variants } from 'shared/components/ui/button';
import { GridContainer } from 'shared/components/ui/grid-container';

import * as Styled from './generic-error.style';

export type GenericErrorProps = {
  errors: string[];
};

export const GenericError: React.FC<GenericErrorProps> = ({ errors }) => {
  const { t } = useTranslation();

  const reload = () => window.location.reload();
  const onContact = () => {
    window.location.href = `mailto:support@griply.app?body=Please don't remove this line "${errors.join(', ')}".`;
  };

  return (
    <Styled.Container>
      <GridContainer>
        <Styled.ContentContainer>
          <Styled.Content>
            <Styled.Body>
              <Typography variant="h1" component="span">
                {t('pages.error.title')}
              </Typography>
              <Typography variant="h5" component="span">
                {t('pages.error.subtitle')}
              </Typography>
              <Typography variant="body2" component="span">
                {t('pages.error.description')}
              </Typography>
            </Styled.Body>

            {errors.map((errorId) => (
              <Styled.IssueLabel key={errorId}>{errorId}</Styled.IssueLabel>
            ))}
          </Styled.Content>

          <Styled.Interactions>
            <Button onClick={reload} size={Sizes.Medium}>
              {t('pages.error.interactions.retry.label')}
            </Button>
            <Button
              variant={Variants.Text}
              size={Sizes.Medium}
              onClick={onContact}
            >
              {t('pages.error.interactions.contact-support.label')}
            </Button>
          </Styled.Interactions>
        </Styled.ContentContainer>
      </GridContainer>
    </Styled.Container>
  );
};
