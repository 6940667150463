import Typography from '@mui/material/Typography';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useMemo } from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { GoalTemplateCard } from 'shared/components/ui/goal-template-card';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { useShowGoalTemplatePreview } from 'shared/contexts/goal-template-preview';
import { useToggle } from 'shared/hooks/use-toggle';
import { GoalTemplate } from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { chunkArray } from 'shared/utils/chunk-array';
import { useTheme } from 'styled-components';

import * as Styled from './category-overview.style';

type ListProps = {
  templates: GoalTemplate[];
  onTemplate: (id: ID) => void;
};

const DesktopList: React.FC<ListProps> = ({ templates, onTemplate }) => (
  <Styled.List>
    {templates.map((template) => (
      <Styled.ListItem key={template.id}>
        <GoalTemplateCard template={template} onClick={onTemplate} />
      </Styled.ListItem>
    ))}
  </Styled.List>
);

const MobileList: React.FC<ListProps> = ({ templates, onTemplate }) => {
  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  });

  const chunkedTemplates = useMemo(() => chunkArray(templates, 3), [templates]);

  return (
    <Styled.EmblaContainer ref={emblaRef}>
      <Styled.EmblaList>
        {chunkedTemplates.map((templatesChunk, index) => (
          <Styled.EmblaListItem key={index}>
            <Styled.EmblaSubList>
              {templatesChunk.map((template) => (
                <Styled.ListItem key={template.id}>
                  <GoalTemplateCard template={template} onClick={onTemplate} />
                </Styled.ListItem>
              ))}
            </Styled.EmblaSubList>
          </Styled.EmblaListItem>
        ))}
      </Styled.EmblaList>
    </Styled.EmblaContainer>
  );
};

export type CategoryOverviewProps = {
  title: string;
  templates: GoalTemplate[];
};

export const CategoryOverview: React.FC<CategoryOverviewProps> = ({
  title,
  templates,
}) => {
  const theme = useTheme();
  const [open, toggleOpen] = useToggle(true);
  const openPreview = useShowGoalTemplatePreview();

  const showPreview = (id: ID) => {
    const template = templates.find((template) => template.id === id);

    if (!template) {
      return;
    }

    openPreview(template);
  };

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Header onClick={toggleOpen}>
          <Styled.IconButtonContainer>
            <IconButton
              icon={ChevronLeft}
              rotation={open ? 270 : 180}
              size={IconButtonSizes.ExtraSmall}
              as="span"
            />
          </Styled.IconButtonContainer>

          <Typography variant="h5" component="span">
            {title}
          </Typography>
        </Styled.Header>
      </Styled.HeaderContainer>

      <Collapse isOpen={open}>
        {theme.isMobile ? (
          <MobileList templates={templates} onTemplate={showPreview} />
        ) : (
          <DesktopList templates={templates} onTemplate={showPreview} />
        )}
      </Collapse>
    </Styled.Container>
  );
};
