import Typography from '@mui/material/Typography';
import { useLocalizedLifeAreaById } from 'features/life-areas';
import { useUpdateVision, useVisionByLifeAreaId } from 'features/vision';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Sources } from 'shared/components/connected/form-illustration';
import { ImageCrossFade } from 'shared/components/ui/image-cross-fade';
import { SortableMultiImageUpload } from 'shared/components/ui/multi-image-upload';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { useToggle } from 'shared/hooks/use-toggle';
import { ImageLocations } from 'shared/services/backend-api';
import { Image } from 'shared/types/image';
import { LimitKeys } from 'shared/types/limit-keys';

import { VisionDescription } from '../vision-description';
import { VisionTips } from '../vision-tips';
import * as Styled from './vision-detail.style';

export const VisionDetail: React.FC = () => {
  const { id } = useParams();
  const lifeArea = useLocalizedLifeAreaById(id);
  const vision = useVisionByLifeAreaId(id);
  const updateVision = useUpdateVision(lifeArea);
  const { t } = useTranslation();
  const [tipsOpen, toggleTipsOpen] = useToggle(!vision?.images);
  const { allowed: isUploadAllowed } = usePremiumFeatureAllowed(
    LimitKeys.VisionUserPhoto,
    {},
  );
  const openPremiumDialog = useOpenPremiumDialog();

  const onTextAreaChange = (description: string) => {
    updateVision({
      ...(vision ?? { lifeAreaId: id }),
      description,
    });
  };

  const onImagesChange = (images: Image[]) => {
    updateVision({
      ...(vision ?? { lifeAreaId: id }),
      images,
    });
  };

  return (
    <Styled.Container>
      <Styled.InputContainer>
        <SortableMultiImageUpload
          images={vision?.images ?? []}
          onChange={onImagesChange}
          sources={[Sources.Unsplash, Sources.Upload]}
          premiumSources={isUploadAllowed ? undefined : [Sources.Upload]}
          onPremium={openPremiumDialog}
          imageLocation={ImageLocations.Vision}
        />
        <Styled.Section>
          <Typography variant="h6" component="h2">
            {t('pages.life-area-detail.vision.text-area.title')}
          </Typography>
        </Styled.Section>
        <VisionDescription
          value={vision?.description}
          onChange={onTextAreaChange}
          placeholder={t(
            'pages.life-area-detail.vision.text-area.placeholder',
            { lifeArea: lifeArea?.name },
          )}
        />
      </Styled.InputContainer>

      <Styled.TipsAndImages>
        {!!vision?.images?.length && (
          <Styled.ImageCarouselContainer $show={!tipsOpen}>
            <ImageCrossFade items={vision?.images} />
          </Styled.ImageCarouselContainer>
        )}

        <Styled.TipsContainer>
          <VisionTips open={tipsOpen} onClick={toggleTipsOpen} />
        </Styled.TipsContainer>
      </Styled.TipsAndImages>
    </Styled.Container>
  );
};
