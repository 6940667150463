import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GoalPlans, Plus } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';

export type AddGoalContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  onClose: () => void;
  lifeAreaId?: ID;
};

export const AddGoalContextMenu = forwardRef<
  HTMLDivElement,
  AddGoalContextMenuProps
>(
  (
    {
      position = 'bottom-end',
      location,
      referenceElement,
      onClose,
      lifeAreaId,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const openPremiumDialog = useOpenPremiumDialog();
    const openCreateGoalDialog = useOpenCreateGoalDialog();

    const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();

    const createGoal = () => {
      createGoalAllowed
        ? openCreateGoalDialog({ lifeAreaId })
        : openPremiumDialog();
      onClose();
    };

    const navigateToGoalTemplates = () => {
      createGoalAllowed ? navigate(Paths.GoalTemplates) : openPremiumDialog();
      onClose();
    };

    return (
      <PopupMenu
        ref={ref}
        location={location}
        referenceElement={referenceElement}
        position={position}
      >
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton start={<Icon icon={Plus} />} onClick={createGoal}>
              {t('context-menus.goal-overview.add-options.labels.create-goal')}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton
              start={<Icon icon={GoalPlans} />}
              onClick={navigateToGoalTemplates}
            >
              {t(
                'context-menus.goal-overview.add-options.labels.navigate-to-goal-templates',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      </PopupMenu>
    );
  },
);
