import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BulletListText,
  BusinessChart,
  Calendar,
  CheckCircle,
  Flag,
  Target,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';

export type ProgressChartContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  onClose: () => void;
  onEditTarget?: () => void;
  onEditStartDate?: () => void;
  onEditDeadline?: () => void;
  onShowHistory?: () => void;
  onUpdateProgress?: () => void;
  onComplete?: () => void;
  isCompleted?: boolean;
};

export const ProgressChartContextMenu: React.FC<
  ProgressChartContextMenuProps
> = ({
  location,
  referenceElement,
  position,
  onClose,
  onEditTarget,
  onEditStartDate,
  onEditDeadline,
  onShowHistory,
  onUpdateProgress,
  onComplete,
  isCompleted,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const _onEditTarget = onEditTarget
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onEditTarget();
      }
    : undefined;

  const _onEditStartDate = onEditStartDate
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onEditStartDate();
      }
    : undefined;

  const _onEditDeadline = onEditDeadline
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onEditDeadline();
      }
    : undefined;

  const _onShowHistory = onShowHistory
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onShowHistory();
      }
    : undefined;

  const _onUpdateProgress = onUpdateProgress
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onUpdateProgress();
      }
    : undefined;

  const _onComplete = onComplete
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        onComplete();
      }
    : undefined;

  useClickOutside(containerRef, onClose);

  return (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
    >
      {(!!_onEditTarget || !!_onUpdateProgress) && (
        <PopupMenuList>
          {!!_onEditTarget && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onEditTarget}
                start={<Icon icon={Target} />}
              >
                {t('context-menus.goal.progress.labels.edit-target')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!_onEditStartDate && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onEditStartDate}
                start={<Icon icon={Calendar} />}
              >
                {t('context-menus.goal.progress.labels.edit-start-date')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!_onEditDeadline && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onEditDeadline}
                start={<Icon icon={Flag} />}
              >
                {t('context-menus.goal.progress.labels.edit-deadline')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!_onUpdateProgress && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onUpdateProgress}
                start={<Icon icon={BusinessChart} />}
              >
                {t('context-menus.goal.progress.labels.update-progress')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!_onShowHistory && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onShowHistory}
                start={<Icon icon={BulletListText} />}
              >
                {t('context-menus.goal.progress.labels.show-history')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!_onComplete && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={_onComplete}
                start={<Icon icon={CheckCircle} />}
              >
                {t(
                  isCompleted
                    ? 'context-menus.goal.progress.labels.un-complete'
                    : 'context-menus.goal.progress.labels.complete',
                )}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
        </PopupMenuList>
      )}
    </PopupMenu>
  );
};
