import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect, Option } from 'shared/components/ui/form-select';
import { RadioInput } from 'shared/components/ui/radio-input';
import { WeekDays } from 'shared/types/week-days';
import { getWeekDays } from 'shared/utils/get-week-days';
import { isNumber } from 'shared/utils/is-number';

import { parseWeekDaysFromRrule, setWeekDaysInRrule } from './parse-rrule';
import * as Styled from './repeat-rrule-form.style';
import { defaultRrule } from './types';

enum RadioOptions {
  SpecificDays = 'specific-days',
  TimesPerWeek = 'times-per-week',
}

const timesPerWeekOptions: Option[] = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
];

export type WeeksFormProps = {
  rrule: string;
  count: number | null;
  weekStartsOn: WeekDays;
  onChange: (weekValues: { value: string; count: number | null }) => void;
};

export const WeeksForm: React.FC<WeeksFormProps> = ({
  rrule,
  count,
  weekStartsOn,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedWeekDays = useMemo(
    () => parseWeekDaysFromRrule(rrule),
    [rrule],
  );
  const timesPerWeekSelected = isNumber(count);

  const onChangeOption = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value === RadioOptions.SpecificDays) {
      onChange({ value: defaultRrule, count: null });
    }
    if (target.value === RadioOptions.TimesPerWeek) {
      onChange({ value: setWeekDaysInRrule([]), count: 1 });
    }
  };

  const onChangeWeekDay = (e: React.MouseEvent) => {
    e.preventDefault();
    const dayValue = e.currentTarget.getAttribute('data-value');

    const newSelectedWeekDays = selectedWeekDays.filter(
      (weekDay) => weekDay !== dayValue,
    );
    if (dayValue && newSelectedWeekDays.length === selectedWeekDays.length) {
      newSelectedWeekDays.push(dayValue as WeekDays);
    }

    onChange({
      value: setWeekDaysInRrule(newSelectedWeekDays),
      count: null,
    });
  };

  const onChangeCount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCount = parseInt(e.target.value);
    onChange({
      value: setWeekDaysInRrule([]),
      count: isNaN(newCount) ? 0 : newCount,
    });
  };

  const weekDayOptions = useMemo(
    () =>
      getWeekDays(weekStartsOn).map((weekDay) => ({
        label: t(
          `general.labels.habit.repeat.value.specific-days.days.${weekDay}.label`,
        ),
        value: weekDay,
      })),
    [t, weekStartsOn],
  );

  return (
    <Styled.FormContainer>
      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.SpecificDays}
          name="week-options"
          checked={!timesPerWeekSelected}
          onChange={onChangeOption}
          value={RadioOptions.SpecificDays}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.SpecificDays}
          >
            {t('forms.habit.fields.repeat.weeks.specific-days.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={timesPerWeekSelected}>
          <Styled.WeekDayList>
            {weekDayOptions.map(({ label, value }) => (
              <Styled.WeekDayListItem key={value}>
                <Styled.DayButton
                  onClick={onChangeWeekDay}
                  data-value={value}
                  $selected={selectedWeekDays.includes(value)}
                  disabled={timesPerWeekSelected}
                  type="button"
                >
                  {label}
                </Styled.DayButton>
              </Styled.WeekDayListItem>
            ))}
          </Styled.WeekDayList>
        </Styled.RadioBody>
      </Styled.RadioContainer>

      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.TimesPerWeek}
          name="week-options"
          checked={timesPerWeekSelected}
          onChange={onChangeOption}
          value={RadioOptions.TimesPerWeek}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.TimesPerWeek}
          >
            {t('forms.habit.fields.repeat.weeks.times-per-week.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={!timesPerWeekSelected}>
          <Styled.TimesPerContainer>
            <FormSelect
              options={timesPerWeekOptions}
              value={count ?? ''}
              onChange={onChangeCount}
              disabled={!timesPerWeekSelected}
              isSmall
            />
            <Typography variant="body2">
              {t('forms.habit.fields.repeat.weeks.times-per-week.post-fix', {
                count: count ?? 1,
              })}
            </Typography>
          </Styled.TimesPerContainer>
        </Styled.RadioBody>
      </Styled.RadioContainer>
    </Styled.FormContainer>
  );
};
