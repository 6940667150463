import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)} 0`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(0.5)} ${theme.spacing(3)}`};
    `};
`;
