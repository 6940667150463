import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
`;

export const Label = styled.label<{ $disabled: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.45;
    `};
`;

export const InputContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const Input = styled.input<{
  $hasError: boolean;
  $hasLeft: boolean;
  $hasRight: boolean;
  $transparent: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  margin: 0;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(2)}`};
  width: 100%;

  background: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  transition: border-color 200ms;

  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.brown['200']};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    opacity: 0.45;
  }

  ${({ theme, $hasError }) =>
    $hasError &&
    css`
      border-color: ${theme.palette.error.main};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(2.5)} ${theme.spacing(3)}`};
    `};

  ${({ $hasLeft, theme }) =>
    $hasLeft &&
    css`
      padding-left: ${theme.spacing(7)};
    `};

  ${({ $hasRight, theme }) =>
    $hasRight &&
    css`
      padding-right: ${theme.spacing(7)};
    `};
`;

export const StartContainer = styled.span`
  position: absolute;
  top: 50%;
  height: 100%;
  left: ${({ theme }) => theme.spacing(2)};

  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;

  transform: translateY(-50%);
`;
export const EndContainer = styled.span<{ $noPointerEvents: boolean }>`
  position: absolute;
  top: 50%;
  height: 100%;
  right: ${({ theme }) => theme.spacing(2)};

  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;

  transform: translateY(-50%);

  ${({ $noPointerEvents }) =>
    $noPointerEvents &&
    css`
      pointer-events: none;
    `}
`;

export const PostFix = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconRightContainer = styled.span`
  line-height: 62.5%;
`;

export const NumberButtonsContainer = styled.span`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing(0.5)};
`;

const ButtonBase = css`
  margin: 0;
  padding: 0;

  background: transparent;
  border: none;
  cursor: pointer;

  font-size: 1.2rem;
  line-height: 62.5%;
`;

export const NumberButton = styled.button<{ $isSecond?: boolean }>`
  ${ButtonBase};

  display: flex;
  align-items: center;
  justify-content: center;

  transform: rotate(${({ $isSecond }) => ($isSecond ? 270 : 90)}deg);

  color: inherit;
`;

export const ClearButton = styled.button`
  ${ButtonBase};

  margin: 0;
  padding: 0;

  border: none;
  background: transparent;

  color: ${({ theme }) => theme.palette.text.secondary};
`;
