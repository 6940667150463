import { RRule } from 'rrule';

export type Options = {
  format: string;
  startDate: Date;
  endDate: Date;
};

export const getRruleDates = ({ format, startDate, endDate }: Options) => {
  const rule = RRule.fromString(format);
  // weirdly we need to set a start and end date on the options for this to work..
  rule.options.dtstart = startDate;
  rule.options.until = endDate;

  return rule.between(startDate, endDate, true);
};

export const getRruleFrequency = (options: Options): number =>
  getRruleDates(options).length;
